import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const assessedApi = createApi({
  reducerPath: "assessedApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_ENDPOINT_V2,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Assessed"],
  endpoints: (builder) => ({
    getAssessedByUser: builder.query({
      query: ({ appraisalId, shopId }) => ({
        url: "/assessed/",
        method: "GET",
        params: { appraisalId, shopId },
      }),
      providesTags: [{ type: "Assessed" }],
    }),
  }),
});

export const { useGetAssessedByUserQuery } = assessedApi;
