import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const improvementRequestApi = createApi({
  reducerPath: "improvementRequestApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_ENDPOINT_V2}`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["ImprovementRequests"],
  endpoints: (builder) => ({
    getImprovementRequests: builder.query({
      query: () => ({
        url: `/improvementRequest/admin`,
        method: "GET",
      }),
      providesTags: [{ type: "ImprovementRequests" }],
    }),
  }),
});

export const { useGetImprovementRequestsQuery } = improvementRequestApi;