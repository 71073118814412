import React, { useState, useEffect } from "react";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogTitle";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "../../components/button";
import {
  useLazyGetJobsQuery,
  usePostWpJsonMutation,
} from "../../app/services/vehicleBrand.api";
import { useGetKeyValueQuery } from "../../app/services/keyValueApi ";

export default function Publish({ newVehicleCreated = false }) {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [isPublishEnabled, setIsPublishEnabled] = useState(false);
  const [isPublishClicked, setIsPublishClicked] = useState(false);

  const [getJobs] = useLazyGetJobsQuery();
  const [postWpJson] = usePostWpJsonMutation();

  const { data: vehicleUpdateStatus, isSuccess } = useGetKeyValueQuery(
    "isVehicleJsonHaveUpdates",
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    const isEnabled =
      vehicleUpdateStatus === true || vehicleUpdateStatus === "true";

    setIsPublishEnabled(isEnabled);
  }, [vehicleUpdateStatus, isSuccess]);

  useEffect(() => {
    let timer;
    if (isPublishClicked) {
      timer = setTimeout(() => {
        setIsPublishClicked(false);
      }, 5 * 60 * 1000);
    }
    return () => clearTimeout(timer);
  }, [isPublishClicked]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const checkJobList = async () => {
    if (isPublishClicked) {
      setOpen(true);
      return;
    }

    setIsPublishClicked(true);

    setOpen(true);

    try {
      const timeoutPromise = new Promise((_, reject) => {
        setTimeout(() => {
          setOpen(false);
          reject(new Error("Request timed out after 5 minutes"));
        }, 5 * 60 * 1000);
      });

      const result = await Promise.race([
        (async () => {
          const response = await getJobs().unwrap();
          const latestJob = response.reduce((latest, job) => {
            const jobStartDate = moment(job.jobStartDate);
            const latestStartDate = moment(latest.jobStartDate || 0);

            return jobStartDate.isAfter(latestStartDate) ? job : latest;
          }, {});

          const currentDate = moment();
          const latestJobStartDate = moment(latestJob.jobStartDate);

          if (
            latestJob.jobStatus === "In Progress" &&
            latestJobStartDate.isSameOrAfter(currentDate, "day")
          ) {
            return "in_progress";
          } else {
            const fileData = {
              bucketName: "ctn-uploads",
              keyName: "latestTest.json",
            };

            const response2 = await postWpJson(fileData).unwrap();
            if (response2.status === 200) {
              setOpen2(true);
              return "success";
            }
          }
          return "error";
        })(),
        timeoutPromise,
      ]);

      return result;
    } catch (e) {
      if (e.message === "Request timed out after 5 minutes") {
        console.error("Operation timed out after 5 minutes");
      } else {
        console.error(e);
      }
      setOpen(false);
      return "error";
    }
  };

  return (
    <>
      <div className="space_right_narrow">
        <Button
          label={
            newVehicleCreated
              ? "変更を公開"
              : isPublishClicked
              ? "変更を公開中"
              // : "変更を公開済み"
              : "変更を公開"
          }
          color={"dark_blue"}
          cloud
          on
          disabled={!isPublishEnabled}
          onClick={checkJobList}
        />
      </div>

      <Dialog
        open={open2}
        onClose={handleClose2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">マスターデータの公開</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            マスター
            データの公開リクエストが正常に送信されました。変更は数分後に Web
            サイトに反映されます。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button label="閉じる" color={"black"} on onClick={handleClose2} />
        </DialogActions>
      </Dialog>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">マスターデータの公開</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>スターデータの公開処理を実施しました。</p>
            現在、公開処理が進行中です。反映が完了するまで約5分お待ちください。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button label="開じる" color={"black"} on onClick={handleClose} />
        </DialogActions>
      </Dialog>
    </>
  );
}