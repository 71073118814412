import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useUploadVehicleBrandInfoCsvMutation } from "../app/services/vehicleBrand.api";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #fe5b02",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function BasicModal(props) {
  const [file, setFile] = useState(null);
  const [presignedUrl, setPresignedUrl] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info"); // 'info' is the default severity
  const [uploadCsv, { isLoading: isUploading }] =
    useUploadVehicleBrandInfoCsvMutation();

  const { open, onclose, acceptCSV = false, refreshTable, refreshHBar } = props;
  const [isLoading, setIsLoading] = useState(false);

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleUpload = async () => {
    setIsLoading(true);

    if (!file) {
      console.error("ファイルが選択されていない");
      setIsLoading(false);
      return;
    }

    try {
      const fileName = `${file.name}`;
      const fileData = {
        bucketName: "ctn-uploads",
        keyName: fileName,
      };

      const fileData2 = {
        bucketName: "ctn-uploads",
        filePath: fileName,
      };

      const token = localStorage.getItem('token');

      const response = await axios.post(
        process.env.REACT_APP_ENDPOINT_V2 + "/file/presigned",
        fileData,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          },
          withCredentials: true  
        }
      );

      const data = response.data;
      setPresignedUrl(data.url);
      const encodedFileName = encodeURIComponent(fileName);

      // Upload the file to S3 using the presigned URL
      const response2 = await axios.put(data.url, file, {
        headers: {
          "Content-Type": file.type,
          "Content-Disposition": `attachment; filename*=UTF-8''${encodedFileName}`,
        },
      });

      if (response2 && response2.status === 200) {
        const upresponse = await uploadCsv(fileData).unwrap();
        setSnackbarSeverity("success");
        setSnackbarMessage("CSVファイルは正常にアップロードされました");
        setOpenSnackbar(true);
      } else {
        // Display an error toast for unsuccessful upload
        setSnackbarSeverity("error");
        setSnackbarMessage(
          "ファイルのアップロードにエラーが発生しました。もう一度お試しください。"
        );
        setOpenSnackbar(true);
      }
    } catch (error) {
      setSnackbarSeverity("error");
      setSnackbarMessage("エラーが発生しました。もう一度お試しください。");
      setOpenSnackbar(true);
    } finally {
      onclose();
      setFile(null);
      setIsLoading(false);
      refreshHBar();
      refreshTable(); // Stop loading
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={onclose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {isLoading ? (
            // Show spinner during loading
            <CircularProgress
              sx={{
                color: "#FE5B02",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            />
          ) : (
            <>
              <Typography
                id="file-upload-modal-title"
                variant="h6"
                component="h2"
                align="center"
              >
                CSVファイルをアップロードする
              </Typography>
              <Typography
                id="file-upload-modal-description"
                align="center"
                sx={{ mt: 2 }}
              >
                アップロードするcsvファイルを選択してください。
              </Typography>
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <label>
                  <Button
                    disabled={!file}
                    onClick={handleUpload}
                    component="div"
                    style={{ background: "#fe5b02", margin: "auto" }}
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    ファイルのアップロード
                  </Button>
                  <Button>
                    <input
                      type="file"
                      {...(acceptCSV && { accept: "text/csv" })}
                      onChange={handleFileChange}
                    />
                  </Button>
                </label>
              </div>
            </>
          )}
        </Box>
      </Modal>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}
