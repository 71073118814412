import React, { useRef, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import getNameByGroupOrNumber from "../utils/vehicle_groups";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import noSearchData from "../assets/images/no-search-data.png";
import ConfirmationModal from "./ConfirmationModal";
import EditFormModal from "./EditFormModal";
import { Pagination as MuiPagination } from "@mui/material";
import {
  useDeleteVehicleBrandInfoMutation,
  useUpdateVehicleBrandInfoMutation,
} from "../app/services/vehicleBrand.api";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-virtualScroller": {
    "&::-webkit-scrollbar": {
      width: 8,
      height: 8,
      background: theme.palette.grey[300],
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.palette.primary.main,
      borderRadius: "10px",
      boxShadow: 2,
      border: 2,
    },
  },
}));

const CustomNoRowsOverlay = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <Typography variant="h5" sx={{ mt: 2 }}>
        検索結果がありません
      </Typography>
      <img src={noSearchData} alt="no-search-data" style={{ width: "50vh" }} />
    </Box>
  );
};

export default function DataListTable(props) {
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [modalType, setModalType] = useState("");
  const [openEditModal, setOpenEditModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [actionLoadingId, setActionLoadingId] = useState(null);
  const [deleteVehicleInfo] = useDeleteVehicleBrandInfoMutation();
  const [updateVehicleInfo] = useUpdateVehicleBrandInfoMutation();
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);

  useEffect(() => {
    if (props.refreshTable) {
      props.refreshTable();
    }
  }, []);

  useEffect(() => {
    if (props.allItems) {
      const formattedData = props.allItems.map((item, index) => ({
        id: index + 1,
        virtualId: item?.virtualId,
        vehicleBrandId: item?.vehicleBrandId,
        matt: item?.matt,
        brand: item?.brand,
        logo: item?.logo,
        vehicleModelId: item?.vehicleModelId,
        vehicleBrandFk: item?.vehicleBrandFk,
        vehicleModelName: item?.vehicleModelName,
        vehicleGroup: getNameByGroupOrNumber(item?.vehicleGroup),
        vehicleYearId: item?.vehicleYearId,
        vehicleModelFk: item?.vehicleModelFk,
        vehicleYearName: item?.vehicleYearName,
        vehicleGradeId: item?.vehicleGradeId,
        vehicleYearFk: item?.vehicleYearFk,
        vehicleGradeName: item?.vehicleGradeName,
      }));
      setTableData(formattedData);
    }
  }, [props.allItems]);

const handlePaginationModelChange = (newModel) => {
  props.setPaginationModel(newModel);
};

// useEffect(() => {
//   if (isPaginationLoading && props.allItems) {
//     setIsPaginationLoading(false);
//   }
// }, [props.allItems]);


  const handleOpenModal = (type, row) => {
    setCurrentRow(row);
    setModalType(type);
    if (type === "edit") {
      setOpenEditModal(true);
    } else {
      setOpenConfirmationModal(true);
    }
  };

  const handleDelete = async (row) => {
    try {
      setIsActionLoading(true);
      setActionLoadingId(row.id);
      
      const {
        vehicleBrandId,
        vehicleModelId,
        vehicleYearId,
        vehicleGradeId,
        brand,
      } = row;

      await deleteVehicleInfo({
        vehicleBrandId,
        vehicleModelId,
        vehicleYearId,
        vehicleGradeId,
      }).unwrap();

      setTableData(prevData => prevData.filter(item => item.id !== row.id));
      
      await props.refreshHBar();
      await props.refreshTable(brand);
      
    } catch (error) {
      console.error("Error deleting item:", error);
    } finally {
      setIsActionLoading(false);
      setActionLoadingId(null);
      setOpenConfirmationModal(false);
    }
  };

  const handleEdit = async (formData) => {
    try {
      setIsActionLoading(true);
      setActionLoadingId(formData.id);
      
      const { vehicleBrandId, vehicleModelId, vehicleYearId, vehicleGradeId } = formData;
  
      await updateVehicleInfo({
        vehicleBrandId,
        vehicleModelId,
        vehicleYearId,
        vehicleGradeId,
        ...formData,
      }).unwrap();
 
      setTableData(prevData => 
        prevData.map(item => 
          item.id === formData.id ? { 
            ...item, 
            ...formData,
            vehicleGroup: getNameByGroupOrNumber(formData.vehicleGroup) 
          } : item
        )
      );
      
      await props.refreshTable(formData?.brand);
      
    } catch (error) {
      console.error("Error updating item:", error);
    } finally {
      setIsActionLoading(false);
      setActionLoadingId(null);
      setOpenEditModal(false);
    }
  };
  
  const columns = [
    {
      field: "id",
      headerName: "No",
      width: 150,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "brand",
      headerName: "メーカー",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "vehicleModelName",
      headerName: "車種",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "vehicleYearName",
      headerName: "年式",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "vehicleGradeName",
      headerName: "グレード",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "vehicleGroup",
      headerName: "グループ",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleOpenModal("edit", params.row)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleOpenModal("delete", params.row)}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <div style={{ height: 600, width: "100%" }}>
      <StyledDataGrid
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        aria-label={"table"}
        rows={tableData}
        columns={columns}
        loading={props.isLoading }
        pageSizeOptions={[5, 10, 25]}
        rowCount={props.dataCount}
        pagination
        paginationMode="server"
        onPaginationModelChange={handlePaginationModelChange}
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
          NoResultsOverlay: CustomNoRowsOverlay,
        }}

        slotProps={{
          pagination: {
            disabled: props.isLoading,
          },
        }}

        sx={{
          "& .MuiDataGrid-virtualScroller .MuiDataGrid-columnsContainer .MuiDataGrid-columnHeaders":
            {
              backgroundColor: "lightblue",
            },
        }}
      />

      {openConfirmationModal && (
        <ConfirmationModal
          open={openConfirmationModal}
          onClose={() => setOpenConfirmationModal(false)}
          onConfirm={() =>
            modalType === "edit"
              ? handleEdit(currentRow)
              : handleDelete(currentRow)
          }
          confirmText={modalType === "edit" ? "Edit" : "Delete"}
        />
      )}
      {openEditModal && (
        <EditFormModal
          open={openEditModal}
          data={currentRow}
          onSave={handleEdit}
          onClose={() => setOpenEditModal(false)}
        />
      )}
    </div>
  );
}