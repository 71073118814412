const getNameByGroupOrNumber = (input) => {
    const data = [
        { code: '80', name: '人気の車種', group: 'ninki' },
        { code: '81', name: '英数字', group: 'number' },
        { code: '82', name: 'ア行', group: 'texta' },
        { code: '83', name: 'カ行', group: 'textka' },
        { code: '84', name: 'サ行', group: 'textsa' },
        { code: '85', name: 'タ行', group: 'textta' },
        { code: '86', name: 'ナ行', group: 'textna' },
        { code: '87', name: 'ハ行', group: 'texha' },
        { code: '88', name: 'マ行', group: 'textma' },
        { code: '89', name: 'ヤ行', group: 'texya' },
        { code: '91', name: 'ラ行', group: 'texra' }
    ];

    const foundItem =
        (!isNaN(input) && typeof input !== 'boolean')
            ? data.find((item) => item?.code === input)
            : data.find((item) => item?.group === input);

    return foundItem ? foundItem?.name : ' ';
};


export default getNameByGroupOrNumber;
