import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const keyValueApi = createApi({
  reducerPath: 'keyValueApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_ENDPOINT_V2,
    credentials: 'include',
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['KeyValue'],
  endpoints: (builder) => ({
    saveKeyValue: builder.mutation({
      query: ({ key, value, expireAt }) => ({
        url: '/kv',
        method: 'POST',
        params: { key, value, expireAt },
      }),
      invalidatesTags: ['KeyValue'],
    }),
    getKeyValue: builder.query({
      query: (key) => ({
        url: '/kv',
        method: 'GET',
        params: { key },
      }),
      providesTags: ['KeyValue'],
    }),
    deleteKeyValue: builder.mutation({
      query: (key) => ({
        url: '/kv',
        method: 'DELETE',
        params: { key },
      }),
      invalidatesTags: ['KeyValue'],
    }),
  }),
});

export const {
  useSaveKeyValueMutation,
  useGetKeyValueQuery,
  useDeleteKeyValueMutation,
} = keyValueApi;
