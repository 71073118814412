import React from "react";
import { Table, Typography, Tag, Spin } from "antd";
import { useGetImprovementRequestsQuery } from "../../app/services/improvementRequest.api";
import moment from "moment";
import { TableLoadingSkeleton } from "../../components";

const { Title } = Typography;

const ImprovementRequest = () => {
  const {
    data: improvementRequests,
    isLoading,
    error,
  } = useGetImprovementRequestsQuery();

  const columns = [
    {
      title: "日時",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "15%",
      render: (text) => moment(text).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "会社名",
      dataIndex: "companyName",
      key: "companyName",
      width: "15%",
    },
    {
      title: "担当者名",
      dataIndex: "personInCharge",
      key: "personInCharge",
      width: "15%",
    },
    {
      title: "メールアドレス",
      dataIndex: "emailAddress",
      key: "emailAddress",
      width: "20%",
    },
    {
      title: "電話番号",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: "15%",
    },
    {
      title: "改善要望詳細",
      dataIndex: "improvementRequest",
      key: "improvementRequest",
      width: "25%",
    },
  ];

  if (isLoading) {
    return (
      <div>
        <TableLoadingSkeleton />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen text-red-500">
        改善要望の読み込みエラー
      </div>
    );
  }

  return (
    <div className="p-6">
      <Table
        columns={columns}
        dataSource={improvementRequests}
        rowKey={(record) => `${record.userId}-${record.createdAt}`}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: false,
          showTotal: (total, range) =>
            `${total}件中 ${range[0]}-${range[1]}件を表示`,
        }}
        scroll={{ x: true }}
        className="bg-white rounded-lg shadow"
      />
    </div>
  );
};

export default ImprovementRequest;
