import { useState } from "react";
import Logo from "../../components/Logo";
import Button from "../../components/button";
import { useBackend } from "../../hooks/useBackend";
import AppraisalList from "./AppraisalList";
import Stack from "@mui/material/Stack";
import MainView from "./MainView";
import Edit from "./Edit";
import { Link } from "react-router-dom";
import Logout from "../auth/logout";
import { useLazyGetFilterCSVAppraisalsQuery } from "../../app/services/fileControlls.api";

export default function AppraisalView() {
  const api = useBackend();
  const [editing, setEditing] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [filterParams, setFilterParams] = useState({});
  const [csvGenerate] = useLazyGetFilterCSVAppraisalsQuery();

  const editClose = () => {
    setEditing(!editing);
  };

  const handleFilterUpdate = (params) => {
    setFilterParams(params);
  };

  const generate_appraisal_list_report = () => {
    setDownloading(true);

    const queryParams = {
      not_compatible: filterParams.not_compatible || false,
      processing: filterParams.processing || false,
      appointment: filterParams.appointment || false,
      closed: filterParams.closed || false,
      unsent: filterParams.unsent || false,
      submit_an_application: filterParams.submit_an_application || false,
      recognition_applied: filterParams.recognition_applied || false,
      recognition_not_accepted: filterParams.recognition_not_accepted || false,
      cancelled: filterParams.cancelled || false,
      seo: filterParams.seo || false,
      paid: filterParams.paid || false,
      paid2: filterParams.paid2 || false,
      paid3: filterParams.paid3 || false,
      paid4: filterParams.paid4 || false,
      phone: filterParams.phone || false,
      phone_no: filterParams.phone_no || "",
      kw: filterParams.kw || "",
      isTestData: filterParams.isTestData || false,
      from: filterParams.fromValue || "",
      to: filterParams.toValue || "",

    };

    csvGenerate(queryParams).then((e) => {
      setTimeout(() => {
        
        setDownloading(false);
        // Create a hidden link to download the file
        const link = document.createElement("a");
        link.href =e.data.url;
        link.download = "data.csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }, 1000);
    });
  };

  return (
    <div className="appraisals">
      <div className="view_header flex">
        <div className="flex">
          <Logo />
          <h1 className="space_around">査定一覧</h1>
          <Button color={"main"} plus circle onClick={editClose} />
          {editing && <Edit onClose={editClose} refresh={editing} />}
        </div>
        <div className="flex">
            <Link to={`/console/dashboard?tab=${0}`}  state={{ previousPage: '/console/appraisals' }} className="space_right">
              <Button label="管理者ポータル" color={"sub"} on />
            </Link>
          <Stack direction="row" spacing={2} sx={{}} alignItems="center">
            <Stack direction={{ lg: "column", xl: "row" }} spacing={1} sx={{}}>
              <Logout />
              <Link to="/console/password-change">
                <Button label="パスワード変更" color={"sub"} on />
              </Link>
            </Stack>
            <Stack direction={{ lg: "column", xl: "row" }} spacing={1} sx={{}}>
              <Link to="/console/register">
                <Button label="新規ユーザー登録" color={"sub"} on />
              </Link>
              {/* TODO : reenable once report query is stable
              <Link to="/console/report">
                <Button label="レポートへ" color={"sub"} on />
              </Link> */}
            </Stack>
            <Stack direction={{ lg: "column", xl: "row" }} spacing={1} sx={{}}>
              <div>
                <Button
                  label="CSV出力"
                  color={"sub"}
                  on
                  onClick={generate_appraisal_list_report}
                  disabled={downloading}
                />
              </div>
            </Stack>
          </Stack>
        </div>
      </div>
      <MainView onFilterChange={handleFilterUpdate}/>
    </div>
  );
}
