import React, { useState } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useReInvoiceMutation } from "../../app/services/invoices.api";
import { Button } from "@mui/material";

function ReconfirmModal(props) {
  const [selectedDate, setSelectedDate] = useState(null);
  const [reInvoiceFunc] = useReInvoiceMutation();
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const onChange = (e) => {
    const requestedDate = `${e.$y}-${e.$M + 1}-${e.$D}`;
    setSelectedDate(requestedDate);
    setIsDatePickerOpen(false);
  };

  const handleClose = () => {
    setIsDatePickerOpen(false);
    props.handleClose();
  };

  const onSubmitHandler = () => {
    const requestData = {
      year: props.year,
      month: props.month,
      shopId: props.shopId,
      selectedDate: selectedDate,
    };
    reInvoiceFunc(requestData)
      .unwrap()
      .then(() => {
        handleClose();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        handleClose();
      });
  };

  return (
    <div>
      <div style={{ textAlign: "left" }}>
        <h2>{props?.transferType}</h2>
      </div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DatePicker"]}>
          <DatePicker
            label="日付を選択"
            onChange={onChange}
            open={isDatePickerOpen}
            onOpen={() => setIsDatePickerOpen(true)}
            onClose={() => setIsDatePickerOpen(false)}
          />
        </DemoContainer>
      </LocalizationProvider>
      <br />
      <Button variant="contained" onClick={onSubmitHandler}>
        確認
      </Button>
    </div>
  );
}

export default ReconfirmModal;
