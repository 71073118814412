import React, { useEffect, useState } from "react";
import { Form, Input, Select, Button, Modal, Alert } from "antd";
import { useCreateVehicleBrandInfoMutation } from "../../app/services/vehicleBrand.api";
import { bodyTypes } from "../../data/body_type_master";

const carCategories = [
  { label: "主な国産車メーカー", value: 80 },
  { label: "主な輸入車メーカー", value: 81 },
  { label: "英数字", value: 82 },
  { label: "ア行", value: 83 },
  { label: "カ行", value: 84 },
  { label: "サ行", value: 85 },
  { label: "タ行", value: 86 },
  { label: "ナ行", value: 87 },
  { label: "ハ行", value: 88 },
  { label: "マ行", value: 89 },
  { label: "ラ行", value: 91 },
  // { label: " ", value: 0 },
];

const carGroup = [
  { code: "80", name: "人気の車種", group: "ninki" },
  { code: "81", name: "英数字", group: "number" },
  { code: "82", name: "ア行", group: "texta" },
  { code: "83", name: "カ行", group: "textka" },
  { code: "84", name: "サ行", group: "textsa" },
  { code: "85", name: "タ行", group: "textta" },
  { code: "86", name: "ナ行", group: "textna" },
  { code: "87", name: "ハ行", group: "texha" },
  { code: "88", name: "マ行", group: "textma" },
  { code: "89", name: "ヤ行", group: "texya" },
  { code: "91", name: "ラ行", group: "texra" },
];

const CreateVehicle = ({ isOpen, onClose, refreshTable, refreshHBar }) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [createVehicleBrandInfo, { isLoading2 }] =
    useCreateVehicleBrandInfoMutation();

  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      await createVehicleBrandInfo(values).unwrap();
      form.resetFields();
      refreshTable();
      refreshHBar();
      onClose();
    } catch (error) {
      if(error.data.status === 400) {
        setAlert(true);}
      setIsLoading(false);
      console.error("Error submitting form:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    onClose();
    setAlert(false);
  };

  useEffect(() => {
    const defaultCarGroup = carGroup.find(
      (group) => group.code === carCategories[0].value.toString()
    );
    if (defaultCarGroup) {
      form.setFieldsValue({
        matt: carCategories[0].value,
        vehicleGroup: defaultCarGroup.group
      });
    }
  }, [form]);

  return (
    <Modal
      open={isOpen}
      onCancel={handleCancel}
      footer={null}
      width={400}
      destroyOnClose
    >
      <Form form={form} onFinish={handleSubmit} className="px-6 py-6">
        <div className="space-y-3">
          {/* <div className="flex items-center">
            <label className="w-24 text-sm">国</label>
            <Form.Item
              name="country"
              className="mb-0 flex-1"
              rules={[
                {
                  required: true,
                  message: "このフィールドに入力してください。",
                },
              ]}
            >
              <Input className="h-8" />
            </Form.Item>
          </div> */}

          <div className="flex items-center">
            <label className="w-24 text-sm">メーカー</label>
            <Form.Item
              name="brand"
              className="mb-0 flex-1"
              rules={[
                {
                  required: true,
                  message: "このフィールドに入力してください。",
                },
              ]}
            >
              <Input className="h-8" />
            </Form.Item>
          </div>

          <div className="flex items-center">
            <label className="w-24 text-sm">車種</label>
            <Form.Item
              name="vehicleModelName"
              className="mb-0 flex-1"
              rules={[
                {
                  required: true,
                  message: "このフィールドに入力してください。",
                },
              ]}
            >
              <Input className="h-8" />
            </Form.Item>
          </div>

          <div className="flex items-center">
            <label className="w-24 text-sm">ボディタイプ1</label>
            <Form.Item
              name="bodyType1"
              className="mb-0 flex-1"
              rules={[
                {
                  required: true,
                  message: "このフィールドに入力してください。",
                },
              ]}
            >
              <Select className="h-8">
              {bodyTypes.map((type) => (
                  <Select.Option key={type.body_type} value={type.body_type}>
                    {type.body_type}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <div className="flex items-center">
            <label className="w-24 text-sm">ボディタイプ2</label>
            <Form.Item
              name="bodyType2"
              className="mb-0 flex-1"
              // rules={[
              //   {
              //     required: true,
              //     message: "このフィールドに入力してください。",
              //   },
              // ]}
            >
              <Select className="h-8">
              {bodyTypes.map((type) => (
                  <Select.Option key={type.body_type} value={type.body_type}>
                    {type.body_type}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <div className="flex items-center">
            <label className="w-24 text-sm">年式</label>
            <Form.Item
              name="vehicleYearName"
              className="mb-0 flex-1"
              rules={[
                {
                  required: true,
                  message: "このフィールドに入力してください。",
                },
              ]}
            >
              <Input className="h-8" />
            </Form.Item>
          </div>

          <div className="flex items-center">
            <label className="w-24 text-sm">グレード</label>
            <Form.Item
              name="vehicleGradeName"
              className="mb-0 flex-1"
              rules={[
                {
                  required: true,
                  message: "このフィールドに入力してください。",
                },
              ]}
            >
              <Input className="h-8" />
            </Form.Item>
          </div>

          <div className="flex items-center">
            <label className="w-24 text-sm">表示箇所</label>
            <Form.Item
              name="matt"
              className="mb-0 flex-1"
              initialValue={carCategories[0]?.value}
            >
              <Select
                className="h-8"
                onChange={(value) => {
                  const selectedCarGroup = carGroup.find(
                    (group) => group.code === value.toString()
                  );
                  if (selectedCarGroup) {
                    setSelectedGroup(selectedCarGroup.group);
                    form.setFieldsValue({ vehicleGroup: selectedCarGroup.group });
                  }
                }}
              >
                {carCategories.map((category) => (
                  <Select.Option key={category.value} value={category.value}>
                    {category.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <Form.Item
            name="vehicleGroup"
            hidden
          >
            <Input />
          </Form.Item>
        </div>

        <div className="flex justify-center space-x-4 mt-6">
          <Button onClick={handleCancel} disabled={isLoading} className="w-20">
            キャンセル
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            className="w-20"
          >
            登録
          </Button>
        </div>
      </Form>
      {alert && (
        <Alert
          message="エラー"
          description="これはすでに存在する"
          type="error"
          showIcon
          closable
          onClose={() => setAlert(false)}
          className="mt-4"
        />
      )}
    </Modal>
  );
};

export default CreateVehicle;
