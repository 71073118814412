import React, { useEffect, useState } from "react";
import { Space, Table, Button, Spin, Select } from "antd";
import { useNavigate } from "react-router-dom";
import {
  useGetAdminUsersQuery,
  useGetAuditsQuery,
} from "../../app/services/audit.api";
import { TableLoadingSkeleton } from "../loading-screens";

const HistoryTable = () => {
  const navigate = useNavigate();
  const [selectedUser, setSelectedUser] = useState(null);
  const [pageParams, setPageParams] = useState({
    page: 0,
    size: 20,
    sort: [],
  });

  const { data: adminUsers, isLoading: isLoadingUsers } =
    useGetAdminUsersQuery();

  const { data, isLoading, error, refetch } = useGetAuditsQuery({
    pageable: {
      page: pageParams.page,
      size: pageParams.size,
      sort: pageParams.sort,
    },
    user: selectedUser,
  });

  const handleOpenDetailPage = (recordId) => {
    navigate(`/console/detail/${recordId}`);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPageParams({
      page: pagination.current - 1,
      size: pagination.pageSize,
      sort: sorter.order
        ? [`${sorter.field},${sorter.order === "ascend" ? "asc" : "desc"}`]
        : [],
    });
  };

  const handleUserFilter = (value) => {
    setSelectedUser(value);
    setPageParams((prev) => ({ ...prev, page: 0 }));
  };

  useEffect(() => {
    refetch();
  }, []);

  const columns = [
    {
      title: "変更日時",
      dataIndex: "changeTime",
      key: "changeTime",
      sorter: false,
      render: (text) => {
        const date = new Date(text);
        return date.toLocaleString("ja-JP", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        });
      },
    },
    {
      title: "変更者",
      dataIndex: "user",
      key: "user",
      sorter: false,
    },
    {
      title: "会社名",
      dataIndex: "companyName",
      key: "companyName",
      sorter: false,
    },
    {
      title: "店名",
      dataIndex: "storeName",
      key: "storeName",
      sorter: false,
    },
    {
      title: "変更項目（カテゴリ）",
      dataIndex: "changedFields",
      key: "changedFields",
      render: (fields) => (Array.isArray(fields) ? fields.join(", ") : "-"),
    },
    {
      title: "操作",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={() => handleOpenDetailPage(record.changeId)}
            className="hover:bg-blue-50"
          >
            詳細
          </Button>
        </Space>
      ),
    },
  ];

  if (isLoading) {
    return (
      <div className="p-4 flex justify-center items-center">
        <TableLoadingSkeleton />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 text-red-500">
        エラーが発生しました: {error.message}
      </div>
    );
  }

  const transformedData =
    data?.content?.map((audit) => ({
      ...audit,
      key: audit.changeId,
    })) || [];

  return (
    <div className="p-4">
      {/* <div className="mb-4 flex items-center">
        <Select
          className="w-64"
          placeholder="ユーザーを選択"
          allowClear
          loading={isLoadingUsers}
          onChange={handleUserFilter}
          value={selectedUser}
        >
          <Select.Option value={null}>全て</Select.Option>
          {adminUsers?.map((user) => (
            <Select.Option key={user} value={user}>
              {user}
            </Select.Option>
          ))}
        </Select>
      </div> */}

      <Table
        columns={columns}
        dataSource={transformedData}
        className="border border-gray-200 rounded"
        pagination={{
          current: (data?.pageable?.page || 0) + 1,
          pageSize: 10,
          total: data?.total || 0,
          showSizeChanger: false,
          showTotal: (total) => `全 ${total} 件`,
          pageSizeOptions: ["10", "20", "50", "100"],
        }}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default HistoryTable;
