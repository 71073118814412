import { Grid, InputLabel, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { statusTypeFilter } from "../../../utils/status-type-filter";
import moment from "moment";

function RStatusSection(props) {
  const typeSet = (item) => {
    const typeMap = {
      "Paid": "Ad",
      "Paid2": "Ad2",
      "Paid3": "Ad3",
      "Paid4": "Ad4",
      "SEO": "SEO",
      "Phone": "架電"
    };
    return typeMap[item] || "";
  }
  return (
    <div>
      <Stack spacing={2}>
        <Typography variant="h5" component="h5" className="text-heading">
          アフィリエイト媒体
        </Typography>
        <Typography variant="h5" component="h5" justifyContent={"center"}>
        {props.data?.savedUtmParam}
        </Typography>
        <Typography variant="h5" component="h5" className="text-heading text-center">
          ステータス
        </Typography>
      </Stack>
      <Grid
        container
        spacing={2}
        direction="row"
        textAlign="center"
        className="panel-card"
      >
        <Grid item xs={12} sm={6} md={6} className="panel-card-grid">
          <InputLabel  className="popup-status">{props.data?.status && statusTypeFilter(props.data?.status)}</InputLabel>
        </Grid>
        <Grid item xs={12} sm={6} md={6} className="panel-card-grid">
          <TextField
            id="note"
            name="note"
            label="備考"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              readOnly: true,
            }}
            value={props.data?.note}
            variant="standard"
            fullWidth
            className="panel-card-text-field"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} className="panel-card-grid">
          <TextField
            id="param"
            name="param"
            label="パラメーター"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              readOnly: true,
            }}
            value={props.data?.param}
            variant="standard"
            fullWidth
            className="panel-card-text-field"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} className="panel-card-grid">
          <TextField
            id="message_for_matching_shop"
            name="message_for_matching_shop"
            label="連絡事項"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              readOnly: true,
            }}
            value={props.data?.messageForMatchingShop}
            variant="standard"
            fullWidth
            className="panel-card-text-field"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} className="panel-card-grid">
          <TextField
            id="created_at"
            name="created_at"
            label="送客日時"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              readOnly: true,
            }}
            value={moment(props.data?.createdAt).format('MM/DD/YYYY HH:mm:ss')}
            variant="standard"
            fullWidth
            className="panel-card-text-field"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} className="panel-card-grid">
          <TextField
            id="shop_type"
            name="shop_type"
            label="経由"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              readOnly: true,
            }}
            value={typeSet(props.data?.type)}
            variant="standard"
            fullWidth
            className="panel-card-text-field"
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default RStatusSection;
