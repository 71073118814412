import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const EditFormModal = ({ open, onClose, onSave, data }) => {
  const [formData, setFormData] = useState(data || {});

  useEffect(() => {
    setFormData(data || {});
  }, [data]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const vehicleGroup = [
    { code: "80", name: "人気の車種", group: "ninki" },
    { code: "81", name: "英数字", group: "number" },
    { code: "82", name: "ア行", group: "texta" },
    { code: "83", name: "カ行", group: "textka" },
    { code: "84", name: "サ行", group: "textsa" },
    { code: "85", name: "タ行", group: "textta" },
    { code: "86", name: "ナ行", group: "textna" },
    { code: "87", name: "ハ行", group: "texha" },
    { code: "88", name: "マ行", group: "textma" },
    { code: "89", name: "ヤ行", group: "texya" },
    { code: "91", name: "ラ行", group: "texra" },
  ];

  const handleSubmit = () => {
    vehicleGroup.map((item) => {
      if (item.name === formData.vehicleGroup) {
        formData.vehicleGroup = item.group;
      }
    });
    onSave(formData);
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="edit-form-modal-title"
    >
      <Box sx={style}>
        <Typography id="edit-form-modal-title" variant="h6" component="h2">
          Edit Item
        </Typography>
        <form>
          <TextField
            fullWidth
            margin="normal"
            label="メーカー (Brand)"
            name="maker"
            value={formData.brand}
            // Brand is read-only
            InputProps={{ readOnly: true }}
          />
          <TextField
            fullWidth
            margin="normal"
            label="車種 (Vehicle Model Name)"
            name="vehicleModelName"
            value={formData.vehicleModelName}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="normal"
            label="グループ (Vehicle Group)"
            name="vehicleGroup"
            value={formData.vehicleGroup}
            // Vehicle Group is read-only
            InputProps={{ readOnly: true }}
          />
          <TextField
            fullWidth
            margin="normal"
            label="年式 (Vehicle Year Name)"
            name="vehicleYearName"
            value={formData.vehicleYearName}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="normal"
            label="グレード (Vehicle Grade Name)"
            name="vehicleGradeName"
            value={formData.vehicleGradeName}
            onChange={handleChange}
          />
          <Box display="flex" justifyContent="flex-end" mt={3}>
            <Button
              onClick={onClose}
              color="secondary"
              style={{ marginRight: "10px" }}
            >
              Cancel
            </Button>
            <Button onClick={handleSubmit} color="primary">
              Save
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default EditFormModal;
