import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import placeholder from "../../assets/images/no-image-available.png";
import { Select, MenuItem, Alert } from "@mui/material";
import axios from "axios";
import { Tooltip } from "@mui/material";
import "./CarManufacturer.css";
import { useUpdateVehicleBrandSummaryMutation } from "../../app/services/vehicleBrand.api";
import { Modal } from "antd";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function CarManufacturer(props) {
  const { imgLink, brand, id, refreshHBar, matt } = props;
  const fileInputRef = useRef(null);
  const [selectedBrand, setSelectedBrand] = useState(matt ?? 0);
  const [updateSummary] = useUpdateVehicleBrandSummaryMutation();
  const [currentImage, setCurrentImage] = useState(imgLink);
  const [sizeAlert, setSizeAlert] = useState(false);
  const [formatAlert, setFormatAlert] = useState(false);
  const [pixelSizeAlert, setPixelSizeAlert] = useState(false);

  // Check image dimensions (fixed width and height)
  const maxWidth = 120; // Set your maximum width
  const maxHeight = 60; // Set your maximum height

  useEffect(() => {
    setCurrentImage(imgLink);
  }, [imgLink]);

  const carCategories = [
    { label: "主な国産車メーカー", value: 80 },
    { label: "主な輸入車メーカー", value: 81 },
    { label: "英数字", value: 82 },
    { label: "ア行", value: 83 },
    { label: "カ行", value: 84 },
    { label: "サ行", value: 85 },
    { label: "タ行", value: 86 },
    { label: "ナ行", value: 87 },
    { label: "ハ行", value: 88 },
    { label: "マ行", value: 89 },
    { label: "ラ行", value: 91 },
    { label: " ", value: 0 },
  ];

  const getBrandCatNameString = (selectedValue) => {
    const selectedCategory = carCategories.find(
      (category) => category?.value === parseInt(selectedValue, 10)
    );
    return selectedCategory ? selectedCategory?.label : " ";
  };

  const getBrandCatName = (digit) => {
    // Define the car lists
    var carLists = {
      80: [46, 49, 53, 47, 48, 42, 41, 50, 44, 80],
      81: [30, 25, 20, 22, 28, 57, 63, 4, 81],
      82: [19, 90, 21, 1, 58, 79, 6, 7, 82],
      83: [40, 92, 8, 33, 2, 46, 34, 23, 72, 73, 83],
      84: [59, 60, 9, 84],
      85: [61, 56, 3, 62, 10, 93, 94, 95, 24, 43, 74, 85],
      86: [96, 64, 65, 11, 45, 97, 32, 75, 86],
      87: [98, 78, 87],
      88: [
        66, 55, 67, 35, 36, 99, 68, 100, 12, 101, 102, 26, 69, 51, 54, 76, 88,
      ],
      89: [27, 13, 37, 103, 70, 104, 29, 106, 31, 14, 107, 52, 89],
      91: [38, 15, 39, 71, 5, 108, 16, 17, 18, 77, 91],
      0: [0],
    };

    // Iterate over carLists and check if the digit is present
    for (var carList in carLists) {
      if (carLists[carList].includes(digit)) {
        return carList;
      }
    }

    // If the digit is not found in any list
    return digit;
  };


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    // Check if a file is selected
    if (file) {
      // Check file type (png, jpeg, jpg)
      const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (!allowedFileTypes.includes(file.type)) {
        setFormatAlert(true);
        // alert(
        //   "ファイルの種類が無効です。PNGまたはJPEG/JPGファイルを選択してください。"
        // );
        resetFileInput();
        return;
      }

      // Check file size (less than 5 MB)
      const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB
      if (file.size > maxSizeInBytes) {
        setSizeAlert(true);
        resetFileInput();
        return;
      }


      const image = new Image();
      image.src = URL.createObjectURL(file);

      image.onload = function () {
        if (image.width > maxWidth || image.height > maxHeight) {
          setPixelSizeAlert(true);
          // alert(
          //   `画像の寸法が許容範囲外です。幅は${maxWidth}px、高さは${maxHeight}px以下に設定してください。`
          // );
          resetFileInput();
          return;
        }

        // If all checks pass, proceed with the upload
        if (image.width <= maxWidth && image.height <= maxHeight) {
          handleUpload(file);
        }
      };
    }
  };

  useEffect(() => {
    if (formatAlert) {
      Modal.warning({
        title: 'エラー',
        content: 'ファイルの種類が無効です。PNGまたはJPEG/JPGファイルを選択してください。',
        onOk() {}
      });
    }
  }, [formatAlert]);

  useEffect(() => {
    if (sizeAlert) {
      Modal.warning({
        title: 'エラー',
        content: 'ファイルサイズが5MBを超えています。より小さいファイルを選択してください。',
        onOk() {}
      });
    }
  }, [sizeAlert]);

  useEffect(() => {
    if (pixelSizeAlert) {
      Modal.warning({
        title: 'エラー',
        content: `画像の寸法が許容範囲外です。幅は${maxWidth}px、高さは${maxHeight}px以下に設定してください。`,
        onOk() {}
      });
    }
  }, [pixelSizeAlert]);

  const handleUpload = async (file) => {
    if (!file) {
      console.error("ファイルが選択されていない");
      return;
    }
    try {
      const image = file;
      const fileName = `${file.name}`;
      const fileData = {
        bucketName: "ctn-uploads",
        keyName: fileName,
      };

      const token = localStorage.getItem('token');
      const response = await axios.post(
        process.env.REACT_APP_ENDPOINT_V2 + "/file/presigned",
        fileData,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          },
          withCredentials: true  
        }
      );
      const imgURL = `https://ctn-uploads.s3.ap-northeast-1.amazonaws.com/${fileName}`;
      const fileData2 = {
        brand: brand,
        logo: imgURL,
        matt: matt,
      };

      const data = response.data;
      // Upload the file to S3 using the presigned URL
      const response2 = await axios.put(data.url, image, {
        headers: {
          "Content-Type": image.type,
          "Content-Disposition": `attachment; filename=${fileName}`,
        },
      });

      if (response2 && response2.status === 200) {
        const imgURL = `https://ctn-uploads.s3.ap-northeast-1.amazonaws.com/${fileName}`;
        const fileData2 = {
          brand: brand,
          logo: imgURL,
          matt: matt,
        };
        
        await updateSummary({ id, data: fileData2 }).unwrap();
        setCurrentImage(imgURL);
        refreshHBar();
      }
    } catch (error) {
      console.error("Error handling upload:", error);
    }
  };

  const handleBrandChange = async (event) => {
    try {
      const newGroup = event.target.value;
      setSelectedBrand(event.target.value);
      const fileData2 = {
        brand: brand,
        logo: currentImage,
        matt: newGroup,
      };
      await updateSummary({ id, data: fileData2 }).unwrap();
      refreshHBar();
    } catch (error) {
      console.error("Error handling upload:", error);
    }
  };

  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };
  // const characterLimit = 5;
  // Truncate the brand text if it exceeds the character limit
  // const truncatedBrand = brand.length > characterLimit ? `${brand.substring(0, characterLimit)}...` : brand;

  return (
    <div className="car-manufacturer-wrapper">
      <div style={{ paddingBlock: "5px" }}>
        <img
          src={currentImage || placeholder}
          alt="Vehicle Brand Logo"
          style={{ width: "120px", height: "60px" }}
        />
      </div>
      <div>
        <strong>{brand}</strong>
      </div>

      <div>
        <Button
          align="center"
          component="label"
          variant="outlined"
          style={{ width: "160px", height: "36px" }}
        >
          <CloudUploadIcon />
          <VisuallyHiddenInput
            type="file"
            accept=".png, .jpeg, .jpg"
            onChange={handleFileChange}
            ref={fileInputRef}
          />
        </Button>
      </div>
      <div style={{ paddingBottom: "5px" }}>
        <Select
          value={getBrandCatName(selectedBrand)}
          onChange={handleBrandChange}
          renderValue={(selected) => getBrandCatNameString(selected)}
          style={{ width: "160px", height: "36px" }}
        >
          {carCategories.map((brand) => (
            <MenuItem key={brand.value} value={brand.value}>
              {brand.label}
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  );
}

export default CarManufacturer;
