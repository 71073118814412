import React from "react";
import { Button, Modal } from "antd";
import placeholder from "../../../assets/images/no-image-placeholder.png";
import ShopReviewRatings from "../../common/reviewStars";

const PreviewScreen = ({ visible, onClose, formData }) => {
 
  const formatShopHolidays = () => {
    if (!formData?.shopHolidays?.length) return "--";
    const dayNames = {
      SUNDAY: "日曜日",
      MONDAY: "月曜日",
      TUESDAY: "火曜日",
      WEDNESDAY: "水曜日",
      THURSDAY: "木曜日",
      FRIDAY: "金曜日",
      SATURDAY: "土曜日",
    };
    return formData.shopHolidays
      .map(({ week, day }) =>
        week && dayNames[day] ? `第${week}${dayNames[day]}` : "-"
      )
      .join(", ");
  };

  const formatShopVacations = () => {
    if (!formData?.shopVacations?.length) return "--";
    const vacation = formData.shopVacations[0];
    return vacation?.start && vacation?.end
      ? `${vacation.start.split(" ")[0]} ~ ${vacation.end.split(" ")[0]}`
      : "--";
  };

  const reviewData = (shops) => ({
    starValue: shops?.starValue,
    starSupport: shops?.starSupport,
    starRecommendation: shops?.starRecommendation,
  });

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      centered
      width="auto"
      style={{ maxWidth: "90vw" }}
    >
      <div className="container mx-auto mt-4 p-4">
        <p className="text-2xl font-bold">{formData?.name ?? "--"}</p>
        <p className="mt-2 text-sm">{formData?.appealStatement ?? "--"}</p>

        <div className="mt-4 flex flex-wrap items-start">
          <div className="w-32 flex-shrink-0 mb-4 md:mb-0">
            <img
              src={formData?.shopImageUrl ?? placeholder}
              alt="Shop"
              className="w-32 h-32 object-contain rounded"
            />
          </div>

          <div className="w-full md:flex-grow md:w-auto md:px-4">
            <table className="w-full text-sm">
              <tbody>
                <tr>
                  <td className="pr-4">住所</td>
                  <td className="break-words">
                    {formData?.postalCode ?? "--"},{" "}
                    {formData?.prefectures ?? "--"},{" "}
                    {formData?.manicipalities ?? "--"},{" "}
                    {formData?.address ?? "--"}
                  </td>
                </tr>
                <tr>
                  <td className="pr-4 ">電話番号</td>
                  <td>{formData?.phone ?? "--"}</td>
                </tr>
                <tr>
                  <td className="pr-4 ">営業時間</td>
                  <td>{formData?.businessHours ?? "--"}</td>
                </tr>
                <tr>
                  <td className="pr-4 ">定休日</td>
                  <td className="break-words">{formatShopHolidays()}</td>
                </tr>
                <tr>
                  <td className="pr-4 ">休暇期間</td>
                  <td>{formatShopVacations()}</td>
                </tr>
                <tr>
                  <td className="pr-4 ">運営</td>
                  <td>{formData?.companyName ?? "--"}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="w-full md:w-auto md:flex-shrink-0 mt-4 md:mt-0">
            <ShopReviewRatings reviews={reviewData(formData.shop)} />
          </div>
        </div>

        <div className="mt-6 flex justify-center">
          <Button onClick={onClose} className="bg-orange-500 text-white">
            買取店情報の編集に戻る
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default PreviewScreen;