import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const vehicleBrandApi = createApi({
  reducerPath: "vehicleBrandApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_ENDPOINT_V2}`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set('credentials', 'include');
      return headers;
    },
  }),
  tagTypes: ["VehicleBrand", "VehicleBrandInfo", "Jobs","VehicleCsvJobStatus"],
  endpoints: (builder) => ({
    getVehicleBrandInfo: builder.query({
      query: ({ skip, limit, search, searchYear }) => ({
        url: `/vehicleBrandInfo`,
        method: "GET",
        params: { skip, limit, search, searchYear }
      }),
      providesTags: ["VehicleBrandInfo"],
    }),

    getVehicleBrands: builder.query({
      query: (search) => ({
        url: `/vehicleBrand`,
        method: "GET",
        params: { search }
      }),
      providesTags: ["VehicleBrand"],
    }),

    getVehicleBrandIds: builder.query({
      query: () => `/vehicleBrand/ids`,
      providesTags: ["VehicleBrand"],
    }),

    createVehicleBrandInfo: builder.mutation({
      query: (data) => ({
        url: `/vehicleBrandInfo`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["VehicleBrandInfo"],
    }),

    updateVehicleBrandInfo: builder.mutation({
      query: ({ vehicleBrandId, vehicleModelId, vehicleYearId, vehicleGradeId, ...data }) => ({
        url: `/vehicleBrandInfo/${vehicleBrandId}/${vehicleModelId}/${vehicleYearId}/${vehicleGradeId}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["VehicleBrandInfo"],
    }),

    deleteVehicleBrandInfo: builder.mutation({
      query: ({ vehicleBrandId, vehicleModelId, vehicleYearId, vehicleGradeId }) => ({
        url: `/vehicleBrandInfo/${vehicleBrandId}/${vehicleModelId}/${vehicleYearId}/${vehicleGradeId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["VehicleBrandInfo"],
    }),

    uploadVehicleBrandInfoCsv: builder.mutation({
      query: (formData) => ({
        url: `/vehicleBrandInfo/upload`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["VehicleBrandInfo"],
    }),

    uploadVehicleBrandWpJson: builder.mutation({
      query: (formData) => ({
        url: `/vehicleBrand/wpjson`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["VehicleBrand"],
    }),

    updateVehicleBrandSummary: builder.mutation({
      query: ({ id, data }) => ({
        url: `/vehicleBrand/summary/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["VehicleBrand"],
    }),

    getJobs: builder.query({
      query: () => ({
        url: '/jobs',
        method: 'GET'
      }),
      providesTags: ["Jobs"],
    }),

    postWpJson: builder.mutation({
      query: (fileData) => ({
        url: '/vehicleBrand/wpjson',
        method: 'POST',
        body: fileData
      }),
      invalidatesTags: ["VehicleBrand"],
    }),
    postWpJsonGen: builder.mutation({
      query: (fileData) => ({
        url: '/vehicleBrandInfo/latestcsv',
        method: 'POST',
        body: fileData
      }),
      invalidatesTags: ["VehicleCsvJobStatus"],
    }),
    getJobStatus: builder.query({
      query: (jobId) => ({
        url: `/vehicleBrandInfo/csv-job-status/${jobId}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetVehicleBrandInfoQuery,
  useGetVehicleBrandInfoYearQuery,
  useGetVehicleBrandsQuery,
  useGetVehicleBrandIdsQuery,
  useCreateVehicleBrandInfoMutation,
  useUpdateVehicleBrandInfoMutation,
  useDeleteVehicleBrandInfoMutation,
  useUploadVehicleBrandInfoCsvMutation,
  useUploadVehicleBrandWpJsonMutation,
  useUpdateVehicleBrandSummaryMutation,
  useLazyGetVehicleBrandInfoQuery,
  useLazyGetVehicleBrandInfoYearQuery,
  useLazyGetVehicleBrandsQuery,
  useLazyGetVehicleBrandIdsQuery,
  useGetJobsQuery,
  useLazyGetJobsQuery,
  usePostWpJsonMutation,
  usePostWpJsonGenMutation,
  useGetJobStatusQuery,
  useLazyGetJobStatusQuery,
} = vehicleBrandApi;