import Papa from 'papaparse';
import { saveAs } from 'file-saver';

const generateAndDownloadCSV = () => {
  var rows = [
    ['メーカー', '車種', '年式', 'グレード', 'グループID', '', '', '', '80 主な国産車メーカー'],
    ['ダイハツ', 'タント', '平成15年 (2003)', '660 L', '80', '', '', '', '81 主な輸入車メーカー'],
    ['ダイハツ', 'ムーブ', '平成15年 (2003)', '660 L', '81', '', '', '', '82 英数字'],
    ['ダイハツ', 'アトレ', '平成15年 (2003)', '660 L', '82', '', '', '', '83 ア行'],
    ['', '', '', '', '', '', '', '', '84 カ行'],
    ['', '', '', '', '', '', '', '', '85 サ行'],
    ['', '', '', '', '', '', '', '', '86 タ行'],
    ['', '', '', '', '', '', '', '', '87 ナ行'],
    ['', '', '', '', '', '', '', '', '88 ハ行'],
    ['', '', '', '', '', '', '', '', '89 マ行'],
    ['', '', '', '', '', '', '', '', '91 ラ行'],
    ['', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '']
  ];

  function encodeCell(value) {
    if (value === undefined || value === null) value = '';
    return '"' + String(value).replace(/"/g, '""') + '"';
  }

  var csvContent = rows.map(row => row.map(encodeCell).join(",")).join("\n");

  var blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csvContent], { type: 'text/csv' });
  var link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = "data.csv";
  link.click();
};

export default generateAndDownloadCSV;
