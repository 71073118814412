import { useEffect, useMemo, useRef, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import Button from "../../components/button";
import { useNavigate } from "react-router-dom";
import Edit from "./Edit";
import Shop from "./Shop";
import MatchConfirm from "./MatchConfirm";
import { statusTypeFilter } from "../../utils/status-type-filter";
import moment from "moment";
import { Modal, Typography, Box } from "@mui/material";
import {
  convertToJapaneseTime,
  convertToGeneralTimeFormat
} from "../../utils/generateTimeList";

export default function AppraisalsTable(props) {
  const navigate = useNavigate();
  const details = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isOpenChoice, setIsOpenChoice] = useState(false);
  const [isOpenMatch, setIsOpenMatch] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [fullRemark, setFullRemark] = useState("");

  const handleOpenModal = (remark) => {
    setFullRemark(remark); // Set the full remark to display
    setOpenModal(true); // Open the modal
  };

  const handleCloseModal = () => {
    setOpenModal(false); // Close the modal
  };

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });

  const editClose = () => {
    setIsEditing(false)
    //props.refreshData();
  };
  const isOpenChoiceClose = () => {
    setIsOpenChoice(false)
    //props.refreshData();
  };
  const isOpenMatchClose = () => {
    setIsOpenMatch(false)
    //props.refreshData();
  };

  useEffect(() => {
    props.onChangePagination &&
      props.onChangePagination(pagination.pageIndex, pagination.pageSize);
  }, [pagination.pageIndex, pagination.pageSize]);

  const translateAssessedStatus = (status) => {
    const statusMap = {
      'unsupported': '未対応',
      'unconnected': '未接続',
      'no_connected_assessments': '接続済み査定無し',
      'assessment_reservation': '查定予約',
      'assessed': '査定済み',
      'unexecuted': '未成約',
      'contracted': '成約済み',
      'user_complaint': 'ユーザークレーム',
      'duplication_of_media': '媒体重複',
      'sale_of_other_companies': '他社売却',
      'processing': '対応中',
      'appointment': 'アポ確定',
      'unsent': '未送客',
      'deleted': '削除',
      'submit_an_application': '却下申請中',
      'recognition_applied': '却下申請承認',
      'recognition_not_accepted': '却下申請棄却',
      'cancelled': 'キャンセル済み'
    };
    
    return statusMap[status] || status || '_';
  };
  

  const columns = useMemo(
    () => [
      {
        accessorKey: "managementStatus",
        enableHiding: false,
        maxSize: 150,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>
            管理ステータス
          </p>
        ),
        Cell: ({ row }) => (
          <span>
            {row?.original?.status
              ? statusTypeFilter(row?.original?.status)
              : "_"}
          </span>
        ),
      },
      {
        accessorKey: "applicationDate",
        enableHiding: false,
        maxSize: 100,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>
            申請日時
          </p>
        ),
        Cell: ({ row }) => (
          <span>
            {row?.original?.supplement?.requestYMD
              ? convertToGeneralTimeFormat(row?.original?.supplement)
              : "_"}
          </span>
        ),
      },
      {
        accessorKey: "sendTime",
        enableHiding: false,
        maxSize: 100,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>
            送客日
          </p>
        ),
        Cell: ({ row }) => (
          <span>
            {row?.original?.assessed?.emailSendTime
              ? convertToJapaneseTime(row?.original?.assessed)
              : "_"}
          </span>
        ),
      },
      {
        accessorKey: "media",
        enableHiding: false,
        maxSize: 100,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>媒体</p>
        ),
        Cell: ({ row }) => (
          <span>
            {row?.original?.type?.toString() === "Paid"
              ? "AD1"
              : row?.original?.type?.toString() === "Paid2"
              ? "AD2"
              : row?.original?.type?.toString() === "Paid3"
              ? "AD3"
              : row?.original?.type?.toString() === "Paid4"
              ? "AD4"
              : row?.original?.type?.toString() === "Phone"
              ? "INSE"
              : row?.original?.type?.toString() === "SEO"
              ? "SEO"
              : "_"}
          </span>
        ),
      },
      // {
      //   accessorKey: "introductionDateTime",
      //   enableHiding: false,
      //   maxSize: 100,
      //   Header: () => (
      //     <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>
      //       紹介日時
      //     </p>
      //   ),
      //   Cell: ({ row }) => (
      //     <span>
      //       {row?.original?.shops?.shops[0]?.assessed_datetime
      //         ? moment(
      //             row?.original?.shops?.shops[0]?.assessed_datetime
      //           ).format("L")
      //         : "_"}
      //     </span>
      //   ),
      // },
      {
        accessorKey: "name",
        enableHiding: false,
        maxSize: 100,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>氏名</p>
        ),
        Cell: ({ row }) => (
          <span>
            {row?.original?.customer?.name ? row?.original?.customer.name : "_"}
          </span>
        ),
      },
      {
        accessorKey: "address",
        enableHiding: false,
        maxSize: 400,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>住所</p>
        ),
        Cell: ({ row }) => {
          const customer = row?.original?.customer;
          if (!customer) return "_";
      
          const formattedPostNumber = customer.post_number
            ? `〒 ${customer.post_number.replace(/(\d{3})(\d{4})/, '$1-$2')}`
            : "";
      
          const address = customer.municipalities?.includes(customer.prefecture)
            ? customer.municipalities
            : `${customer.prefecture || ''}${customer.municipalities || ''}`;
      
          return (
            <div>
              <div style={{ marginBottom: '2px' }}>{formattedPostNumber || "_"}</div>
              <div style={{ 
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}>
                {address || "_"}
              </div>
            </div>
          );
        },
      },
      {
        accessorKey: "phoneNumber",
        enableHiding: false,
        maxSize: 100,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>
            電話番号
          </p>
        ),
        Cell: ({ row }) => (
          <span>
            {row?.original?.customer?.phone?.content
              ? row?.original?.customer?.phone?.content
              : "_"}
          </span>
        ),
      },
      {
        accessorKey: "email",
        enableHiding: false,
        maxSize: 100,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>
            メールアドレス
          </p>
        ),
        Cell: ({ row }) => (
          <span>
            {row?.original?.customer?.email?.content
              ? row?.original?.customer?.email?.content
              : "_"}
          </span>
        ),
      },
      {
        accessorKey: "carType",
        enableHiding: false,
        maxSize: 150,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>車種</p>
        ),
        Cell: ({ row }) => (
          <span>
            {row?.original?.car?.car_type ? row?.original?.car?.car_type : "_"}
          </span>
        ),
      },
      {
        accessorKey: "carGrade",
        enableHiding: false,
        maxSize: 180,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>
            グレード{" "}
          </p>
        ),
        Cell: ({ row }) => (
          <span>
            {row?.original?.car?.grade ? row?.original?.car?.grade : "_"}
          </span>
        ),
      },
      {
        accessorKey: "car_model_year",
        enableHiding: false,
        maxSize: 150,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>年式 </p>
        ),
        Cell: ({ row }) => (
          <span>
            {row?.original?.car?.car_model_year
              ? row?.original?.car?.car_model_year
              : "_"}
          </span>
        ),
      },
      {
        accessorKey: "car_traveled_distance",
        enableHiding: false,
        maxSize: 150,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>距離</p>
        ),
        Cell: ({ row }) => (
          <span>
            {row?.original?.car?.car_traveled_distance
              ? row?.original?.car?.car_traveled_distance
              : "_"}
          </span>
        ),
      },
      {
        accessorKey: "body_color",
        enableHiding: false,
        maxSize: 150,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>
            ボディカラー
          </p>
        ),
        Cell: ({ row }) => (
          <span>
            {row?.original?.car?.body_color
              ? row?.original?.car?.body_color
              : "_"}
          </span>
        ),
      },
      {
        accessorKey: "desire_date",
        enableHiding: false,
        maxSize: 100,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>
            売却時期
          </p>
        ),
        Cell: ({ row }) => (
          <span>
            {row?.original?.car?.desire_date
              ? row?.original?.car?.desire_date
              : "_"}
          </span>
        ),
      },
      {
        accessorKey: "loan",
        enableHiding: false,
        maxSize: 150,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>
            ローン残債
          </p>
        ),
        Cell: ({ row }) => (
          <span>
            {row?.original?.car?.loan ? row?.original?.car?.loan : "_"}
          </span>
        ),
      },
      {
        accessorKey: "dateOfAssessment",
        enableHiding: false,
        maxSize: 100,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>
            査定候補日
          </p>
        ),
        Cell: ({ row }) => (
          <span>
            {row?.original?.aDates?.content[0]?.content
              ? moment(row?.original?.aDates?.content[0]?.content).format("L")
              : "_"}
          </span>
        ),
      },
      {
        accessorKey: "parameter",
        enableHiding: false,
        maxSize: 100,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>
            パラメータ
          </p>
        ),
        Cell: ({ row }) => (
          <span>
            {row?.original?.supplement?.param ? row?.original?.supplement?.param : "_"}
          </span>
        ),
      },
      {
        accessorKey: "remark",
        enableHiding: false,
        maxSize: 100,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>備考</p>
        ),
        Cell: ({ row }) => {
          const remark = row?.original?.supplement?.note || "_";
          const truncatedRemark = remark.length > 18 ? `${remark.substring(0, 18)}...` : remark;

          return (
            <div>
              <span>{truncatedRemark}</span>
              {remark.length > 18 && (
                <button
                  style={{ marginLeft: "8px", color: "blue", cursor: "pointer", border: "none", background: "none" }}
                  onClick={() => handleOpenModal(remark)}
                >
                  詳細
                </button>
              )}
            </div>
          );
        },
      },
      {
        accessorKey: "storeStatus01",
        enableHiding: false,
        maxSize: 100,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>
            買取店ST1
          </p>
        ),
        Cell: ({ row }) => (
          <span>
            {translateAssessedStatus(row?.original?.shops?.shops[0]?.assessed_status)}
          </span>
        ),
      },
      {
        accessorKey: "store01",
        enableHiding: false,
        maxSize: 100,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>
            送客店名1
          </p>
        ),
        Cell: ({ row }) => (
          <span>
            {row?.original?.shops?.shops[0]?.name ? row?.original?.shops?.shops[0]?.name : "_"}
          </span>
        ),
      },
      {
        accessorKey: "storeStatus02",
        enableHiding: false,
        maxSize: 100,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>
             買取店ST2
          </p>
        ),
        Cell: ({ row }) => (
          <span>
           {translateAssessedStatus(row?.original?.shops?.shops[1]?.assessed_status)}
          </span>
        ),
      },
      {
        accessorKey: "store02",
        enableHiding: false,
        maxSize: 100,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>
            送客店名2
          </p>
        ),
        Cell: ({ row }) => (
          <span>
            {row?.original?.shops?.shops[1]?.name ? row?.original?.shops?.shops[1]?.name : "_"}
          </span>
        ),
      },
      {
        accessorKey: "storeStatus03",
        enableHiding: false,
        maxSize: 100,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>
            買取店ST3
          </p>
        ),
        Cell: ({ row }) => (
          <span>
            {translateAssessedStatus(row?.original?.shops?.shops[2]?.assessed_status)}
          </span>
        ),
      },
      {
        accessorKey: "store03",
        enableHiding: false,
        maxSize: 100,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>
            送客店名3
          </p>
        ),
        Cell: ({ row }) => (
          <span>
            {row?.original?.shops?.shops[2]?.name ? row?.original?.shops?.shops[2]?.name : "_"}
          </span>
        ),
      },
      {
        accessorKey: "actionButtons",
        enableHiding: false,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}></p>
        ),
        Cell: ({ row }) => {
          return (
            <div className="each_column buttons">
              <Button
                label="買取店選択"
                color={"sub"}
                on
                onClick={() => {
                  details.current = row?.original;
                  setIsOpenChoice(true);
                }}
              ></Button>
              <Button
                label="編集"
                color={"sub"}
                on
                onClick={() => {
                  details.current = row?.original;
                  setIsEditing(true);
                }}
              ></Button>
              <Button
                label="送客"
                color={"main"}
                on
                onClick={() => {
                  details.current = row?.original;
                  setIsOpenMatch(true);
                }}
              ></Button>
              {row?.original?.test_email_sent && (
                <div style={{ textAlign: 'center', marginTop: '10px' }}>
                  <Typography sx={{ fontSize: "smaller" }} color="error">
                    テストメール送信済
                  </Typography>
                </div>
              )}
            </div>
          );
        },
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: props.data,
    getSubRows: (row) => row.branches,
    enableExpanding: false,
    enableRowSelection: false,
    enableColumnOrdering: false,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableGlobalFilter: false,
    enableTopToolbar: false,
    enableSorting: false,
    enableFilters: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    muiTableContainerProps: {
      sx: {
        maxHeight: 'calc(100vh - 200px)',
        position: 'relative',
        zIndex: 0,
      }
    },
    muiTablePaperProps: {
      sx: {
        position: 'relative',
        zIndex: 0,
      },
    },
    muiBottomToolbarProps: {
      sx: {
        position: 'relative',
        zIndex: 0,
      },
    },
    rowCount: props.rowCount,// This should come with backend response
    state: {
      pagination,
    },
    initialState: {
      columnPinning: { right: ["actionButtons"] },
    },
    onPaginationChange: setPagination,
    manualPagination: true,
    localization: {
      rowsPerPage: "ページあたりの行数",
      of: "の",
    },
  });

  return (
    <div className="material-react-table-body">
      <MaterialReactTable table={table} />
      
      {isEditing && (
        <div style={{ position: 'relative', zIndex: 1001 }}>
          <Edit data={details.current} onClose={editClose} refreshData={props.refreshData} />
        </div>
      )}

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        sx={{
          position: 'fixed',
          zIndex: 1002,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)', // Centers the modal
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4, // Padding inside the modal
            borderRadius: 2,
            zIndex: 1002,
          }}
        >
          <Typography variant="h6" component="h2">
            備考
          </Typography>
          <Typography sx={{ mt: 2, mb: 2 }}>
            {fullRemark}
          </Typography>
          <Button
                s
                on
                label="閉じる"
                color={"gray"}
                onClick={handleCloseModal}
              />
        </Box>
      </Modal>

      {isOpenChoice && (
        <div style={{ position: 'relative', zIndex: 1001 }}>
          <Shop
            data={details.current}
            onClose={isOpenChoiceClose}
            appraisalid={details.current?.appraisalid?.content}
            refreshData={props.refreshData}
          />
        </div>
      )}

      {isOpenMatch && (
        <div style={{ position: 'relative', zIndex: 1001 }}>
          <MatchConfirm data={details.current} onClose={isOpenMatchClose} refreshData={props.refreshData} />
        </div>
      )}
    </div>
  );
}
