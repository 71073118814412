import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { appraisalsApi } from './services/appraisals.api'
import { invoicesApi } from './services/invoices.api'
import { noticesApi } from './services/notices.api'
import { fileControllsApi } from './services/fileControlls.api'
import { passwordResetApi } from './services/password.api'
import { rejectApi } from './services/reject.api'
import { auditApi } from './services/audit.api'
import { vehicleBrandApi } from './services/vehicleBrand.api'
import { improvementRequestApi } from './services/improvementRequest.api'
import { assessedApi } from './services/assessed.api'
import { keyValueApi } from './services/keyValueApi '

export const store = configureStore({
  reducer: {
    [invoicesApi.reducerPath]: invoicesApi.reducer,
    [appraisalsApi.reducerPath]: appraisalsApi.reducer,
    [noticesApi.reducerPath]: noticesApi.reducer,
    [fileControllsApi.reducerPath]: fileControllsApi.reducer,
    [passwordResetApi.reducerPath]: passwordResetApi.reducer,
    [rejectApi.reducerPath]: rejectApi.reducer,
    [auditApi.reducerPath]: auditApi.reducer,
    [improvementRequestApi.reducerPath]: improvementRequestApi.reducer,
    [vehicleBrandApi.reducerPath]: vehicleBrandApi.reducer,
    [assessedApi.reducerPath]: assessedApi.reducer,
    [keyValueApi.reducerPath]: keyValueApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(invoicesApi.middleware, appraisalsApi.middleware, noticesApi.middleware, fileControllsApi.middleware, passwordResetApi.middleware,rejectApi.middleware,auditApi.middleware,improvementRequestApi.middleware,vehicleBrandApi.middleware,assessedApi.middleware,keyValueApi.middleware),

},
)

setupListeners(store.dispatch)