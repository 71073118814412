import React, { useEffect, useState } from "react";
import "./buyerRegisterForm.css";
import { Input } from "../../common/input";
import { FormItem } from "../../common/formItem";
import { Form } from "../../common/form";
import { Col, Row } from "../../common/grid";
import { Select } from "../../common/select";
import { Radio } from "../../common/radio";
import { Checkbox } from "../../common/checkbox";
import { Uploader } from "../../common/uploader";
import { Inputcard } from "../inputCard/inputcard";
import { Button } from "antd";
import { fetchAllHeadBranches } from "../../../app/services/headBranch.service";
import { DateTimePicker } from "../../common/datepicker";
import {
  CheckEmailValidation,
  RegisterBuyer,
} from "../../../app/services/buyer.services";
import { uploadImage } from "../../../app/services/upload.service";
import { useAppStore } from "../../../stores/app.store";
import {
  getShopById,
  updateShopBuyer,
} from "../../../app/services/shop.service";
import { useFetchShopByIdHook } from "../../../hooks/useShopHook";
import dayjs from "dayjs";
import axios from "axios";
import {
  setCustomErrorToast,
  setSuccessToast,
} from "../../../utils/notifications";
import { TableLoadingSkeleton } from "../../loading-screens";
import { useEmailValidation } from "../../../hooks/useExistEmailChecker";
import { getZipCodes } from "../../../app/services/zipcode.service";
import {
  generateHourOptions,
  generateMinuteOptions,
  generateTimeOptions,
} from "../../../utils/generateTimeList";
import { useSearchParams } from "react-router-dom";
import { useBuyerStore } from "../../../stores/buyers.store";
import { Form as AntdForm } from "antd";
import PreviewScreen from "./previewScreen";

export const BuyerRegisterForm = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const tab = urlParams.get("tab");
  const shopId = urlParams.get("id");
  const [headBranches, setHeadBranches] = useState([]);
  const { clearImage } = useBuyerStore();
  const [pageLoading, setPageLoading] = useState(false);
  const [file, setFile] = useState(null);
  const {
    editRegisterFormStatus,
    editRegisterId,
    setMainTabIndex,
    mainTabIndex,
  } = useAppStore();
  const { shop, loading, error } = useFetchShopByIdHook(shopId);
  const [result, setResult] = useState([]);
  const [fileInfo, setFileInfo] = useState(null);
  const [selectBranchType, setSelectbranchType] = useState();
  const [defaultResult, setDefaultResult] = useState([]);
  const [selectedShopExclusivety, setSelectedShopExclusivety] = useState("");
  const [inputValues, setInputValues] = useState({
    postCode: "",
    prefectures: "",
    municipalities: "",
    Address: "",
  });
  const [zipCode, setZipCode] = useState("");
  const [billingPostalCode, setBilingPostalCode] = useState("");
  const [zipCodeResult, setZipCodeResult] = useState();
  const [billlingZipCodeResult, setBillingZipCodeResult] = useState();
  const [businessHours, setBusinessHours] = useState({
    startHour: "08",
    startMinute: "00",
    endHour: "22",
    endMinute: "30",
  });
  const [form] = AntdForm.useForm();
  const [previewVisible, setPreviewVisible] = useState(false);
  const [formValues, setFormValues] = useState({});

  const fetchZipCode = async (zipCode) => {
    const response = await getZipCodes(zipCode);
    setZipCodeResult(response);
    if (response === null) {
      setCustomErrorToast("郵便番号を入力してください");
    }
  };

  const fetchBillingZipCode = async (billingPostalCode) => {
    const response = await getZipCodes(billingPostalCode);
    setBillingZipCodeResult(response);
    if (response === null) {
      setCustomErrorToast("郵便番号を入力してください");
    }
  };
  const [zipCodeError, setZipCodeEror] = useState(false);

  useEffect(() => {
    const isValidZipCode = /^\d{3}-\d{4}$|^\d{7}$/.test(billingPostalCode);
    const isValidSecondZipCode = /^\d{3}-\d{4}$|^\d{7}$/.test(zipCode);

    if (isValidSecondZipCode && zipCode?.length > 6) {
      fetchZipCode(zipCode);
    }

    if (isValidZipCode && billingPostalCode?.length > 6) {
      fetchBillingZipCode(billingPostalCode);
      setZipCodeEror(false);
    } else {
      setZipCodeEror(true);
    }
  }, [zipCode, billingPostalCode]);

  const [copyData, setCopyData] = useState({
    postCode: "",
    prefectures: "",
    municipalities: "",
    Address: "",
    billingDepartment: "",
  });
  const [pasteDataCheck, setPasteDataCheck] = useState(false);
  const [editPasteDataCheck, setEditPasteDataCheck] = useState(false);
  const dropdownTimeOptions = generateTimeOptions();

  const hourOptions = generateHourOptions();
  const minuteOptions = generateMinuteOptions();

  useEffect(() => {
    if (pasteDataCheck && !editPasteDataCheck) {
      setInputValues({
        postCode: copyData.postCode,
        prefectures: copyData.prefectures,
        municipalities: copyData.municipalities,
        Address: copyData.Address,
      });
    } else if (shopId && editPasteDataCheck) {
      setInputValues({
        postCode: zipCode,
        prefectures: copyData.prefectures,
        municipalities: copyData.municipalities,
        Address: shop ? copyData.Address : "",
      });
    }
  }, [pasteDataCheck, copyData, editPasteDataCheck, shopId]);

  const handleChange = (field) => (e) => {
    const value = e.target.value;
    setInputValues((prev) => ({ ...prev, [field]: value }));
    setCopyData((prev) => ({ ...prev, [field]: value }));
    if (field === "postCode") {
      setEditPasteDataCheck(false);
      setBilingPostalCode(value);
    }
  };

  const [initialValues, setInitialValues] = useState();
  const [isChecked, setIsChecked] = useState(false);
  const [vacationStart, setVactionStart] = useState(
    shop ? shop?.shopVacations[0]?.start : null
  );
  const [vacationEnd, setVactionEnd] = useState(
    shop ? shop?.shopVacations[0]?.end : null
  );

  const handleCheckboxChange = (checked) => {
    setIsChecked(checked);
  };

  useEffect(() => {
    let mappedData = [{}];

    if (shop) {
      mappedData = shop?.billingDataInvoices?.map((invoice) => ({
        postCode: invoice.zipCode || "",
        prefectures: invoice.billingPrefecture || "",
        municipalities: invoice.billingMuncipalities || "",
        Address: invoice.billingAddress || "",
        billingDepartment: invoice.billingDepartment || "",
        pic: invoice.pic || "",
      }));

      if (mappedData?.length > 0) {
        setCopyData(mappedData[0]);
        setPasteDataCheck(true);
        setBilingPostalCode(mappedData[0].postCode);
      }
      setDefaultResult(shop?.shopHolidays);
      setBillingZipCodeResult({
        ...billlingZipCodeResult,
        pref: mappedData[0].prefectures,
        town: mappedData[0].municipalities,
      });
      setVactionStart(shop?.shopVacations[0]?.start);
      setVactionEnd(shop?.shopVacations[0]?.end);
      setResult(shop?.shopHolidays);
      setSelectedShopExclusivety(shop.shopExclusivity);
      setZipCode(shop?.postalCode);

      handleCheckboxChange(shop?.holidayMatching);
      setSelectbranchType(shop?.shopTypeEnum);
    }

    setInitialValues({
      companyName: shop ? shop.companyName : "",
      name: shop ? shop.name : "",
      shopTypeEnum: shop ? shop.shopTypeEnum : "",
      headBranchId: shop ? shop.headBranchId : null,
      storeSubscriptionType: shop ? shop.storeSubscriptionType : null,
      email: shop ? shop.username : "",
      phone: shop ? shop.phoneNumber : "",
      postalCode: shop?.postalCode,
      prefectures: zipCodeResult?.pref,
      manicipalities: zipCodeResult?.town,
      address: shop ? shop.address : "",
      shopExclusivity: shop ? shop.shopExclusivity : "",
      exIntroductionFee: Number(shop ? shop.refferal : null),
      nonExReferralFee: Number(shop ? shop.introduction : null),
      paymentType: shop ? shop.paymentMethod : "",
      pic: mappedData[0]?.pic,
      billingEmails:
        shop?.billingDataInvoices?.[0]?.billingEmails?.[0]?.email ?? "",
      startDate: dayjs("2024-03-01"),
      billingDepartment: mappedData[0].billingDepartment,
      appealStatement: shop ? shop.appealStatement : "",
      businessHours: shop ? shop.businessHours : "",
      shopVacations: [
        {
          start: shop ? shop?.shopVacations[0]?.start : "",
          end: shop ? shop?.shopVacations[0]?.end : "",
        },
      ],
      holidayMatching: shop ? shop.holidayMatching : false,
      shopHolidays: shop ? shop.result : null,
      cancellationCategory: shop ? shop?.cancellationCategory : "NONE",
      billingDataInvoices: [
        {
          zipCode: billingPostalCode,
          billingPrefecture: shop ? mappedData[0]?.billingPrefecture : "",
          billingMunicipalities: shop
            ? mappedData[0]?.billingMunicipalities
            : "",
          billingAddress: shop ? shop.billingAddress : copyData.billingAddress,
          billingDepartment: shop ? mappedData[0].billingDepartment : "",
          pic: shop ? shop.companyName : "",
        },
      ],
      shopImageUrl: shop ? shop.shopImageUrl : "",
    });
  }, [shop]);

  const pasteData = () => {
    setPasteDataCheck(true);
    if (editRegisterFormStatus || shopId) {
      setEditPasteDataCheck(true);
    }
    setBilingPostalCode(zipCode);
  };

  const getAllBranch = async () => {
    try {
      const response = await fetchAllHeadBranches();
      setHeadBranches(response);
    } catch (error) {
      console.log(error);
      if (error.status == 401) {
        setCustomErrorToast("長時間操作がなかったため、セッションが切れました。再度ログインしてください。");
      }
    }
  };
  useEffect(() => {
    getAllBranch();
  }, []);

  useEffect(() => {
    if (shop?.businessHours) {
      const businessHoursParts = shop.businessHours.split("~");

      if (businessHoursParts.length === 2) {
        const [startTime, endTime] = businessHoursParts;

        const startParts = startTime?.split(":");
        const endParts = endTime?.split(":");

        if (startParts?.length === 2 && endParts?.length === 2) {
          const [startHour, startMinute] = startParts;
          const [endHour, endMinute] = endParts;

          setBusinessHours({
            startHour,
            startMinute,
            endHour,
            endMinute,
          });
        } else {
          console.error("Invalid time format:", startTime, endTime);
        }
      } else {
        console.error("Invalid businessHours format:", shop.businessHours);
      }
    } else {
      console.error("shop or shop.businessHours is undefined");
    }
  }, [shop]);

  const headBranchoptions = headBranches?.map((branch) => ({
    label: branch.name,
    value: branch.ShopId,
  }));

  const exclusiveOptions = [
    {
      label: "独占",
      value: "EX",
    },
    {
      label: "非独占",
      value: "NON_EX",
    },
    // {
    //   label: "独占／非独占",
    //   value: "BOTH",
    // },
  ];

  const paymentMethods = [
    {
      label: "銀行振込",
      value: "BILLING",
    },
    {
      label: "口座振替",
      value: "DEBIT",
    },
  ];

  const branchTypes = [
    {
      label: "支店",
      value: "SUB_BRANCH",
    },
    {
      label: "本店",
      value: "HEAD_BRANCH",
    },
  ];

  const shopTypes = [
    {
      label: "一般店",
      value: "GENERAL",
    },
    {
      label: "専門店",
      value: "SPECIAL",
    },
  ];

  const cancellationCategory = [
    {
      label: "なし",
      value: "NONE",
    },
    {
      label: "解約済み",
      value: "CANCELLED",
    },
    {
      label: "停止中",
      value: "SUSPENDED",
    },
  ];

  useEffect(() => {
    if (editRegisterFormStatus || editRegisterId) {
      urlParams.set("id", editRegisterId);
      window.history.pushState(
        {},
        "",
        `${window.location.pathname}?${urlParams.toString()}`
      );
    }
  }, [editRegisterFormStatus]);

  const onFinish = async (value) => {
    let isvalid;
    if (!(shop?.username == value?.email) || !shopId || (shop?.username == value?.email)) {
      try {
        
        if(!(shop?.username == value?.email) || !shopId){
          const response = await CheckEmailValidation(value.email);      
        }
          
        isvalid = true;
      } catch (error) {
        isvalid = false;
        if (error.status == 401) {
          setCustomErrorToast("長時間操作がなかったため、セッションが切れました。再度ログインしてください。");
        } else if (shop?.username == value.email || !shopId || !(shop?.username == value?.email)) {
          setCustomErrorToast("電子メールはすでに使用されています");
        }
      }
    }

    if (isvalid || editRegisterFormStatus || shopId) {
      let filename;
      const now = new Date();
      const timestamp = now.toISOString().replace(/[:.-]/g, "");

      try {
        setPageLoading(true);
        if (fileInfo) {
          filename = `${timestamp}.${fileInfo.type
            .split("/")
            .pop()
            .toLowerCase()}`;
          const fileData = {
            bucketName: "ctn-uploads",
            keyName: filename,
          };

          const response = await uploadImage(fileData);

          const response2 = await axios.put(response.url, file, {
            headers: {
              "Content-Disposition": `attachment; filename=${filename}`,
            },
          });
          filename = `${timestamp}.${fileInfo?.type
            .split("/")
            .pop()
            .toLowerCase()}`;
        }

        const newData = {
          companyName: value.companyName,
          name: value.name,
          shopTypeEnum: value.shopTypeEnum,
          headBranchId:
            selectBranchType === "HEAD_BRANCH" ? null : value.headBranchId,
          storeSubscriptionType: value.storeSubscriptionType,
          ...(!shopId ? { email: value.email } : { username: value.email }),
          // email: value.email,
          phoneNumber: value.phone,
          postalCode: value.postalCode,
          prefectures: zipCodeResult?.pref,
          manicipalities: zipCodeResult?.town,
          appealStatement: value.appealStatement,
          businessHours: `${businessHours.startHour}:${businessHours.startMinute}~${businessHours.endHour}:${businessHours.endMinute}`,
          address: value.address,
          shopExclusivity: value.shopExclusivity,
          introduction: Number(value.nonExReferralFee),
          refferal: Number(value.exIntroductionFee),
          paymentMethod: value.paymentType,
          cancellationCategory: value.cancellationCategory,
          shopVacations:
            vacationStart === null && vacationEnd === null
              ? []
              : [
                  {
                    id: null,
                    start: `${vacationStart}.000`,
                    end: `${vacationEnd}.000`,
                  },
                ],
          holidayMatching: isChecked,
          shopHolidays: result.map((item, index) => ({
            ...item,
            id: null,
            week: item.week,
          })),
          billingDataInvoices: [
            {
              zipCode: billingPostalCode,
              billingPrefecture: billlingZipCodeResult?.pref,
              billingMuncipalities: billlingZipCodeResult?.town,
              billingAddress: copyData.Address,
              billingDepartment: value.billingDepartment,
              pic: value.pic,
              billingEmails: [
                {
                  email: value?.billingEmails ?? {},
                },
              ],
            },
          ],
          shopImageUrl: clearImage
            ? ""
            : fileInfo
            ? `https://d3jbkxdk2o6mwn.cloudfront.net/${filename}`
            : shop
            ? shop?.shopImageUrl
            : null,
        };

        if (editRegisterFormStatus || shopId) {
          const updateResponse = await updateShopBuyer(0, {
            ...newData,
            id: editRegisterId || shopId,
          });
          setPageLoading(true);
          setSuccessToast("更新が完了しました");
          setPageLoading(false);
          setMainTabIndex(true);
        } else {
          setPageLoading(true);
          const registerResponse = await RegisterBuyer(newData);
          setSuccessToast("登録が完了しました");
          setPageLoading(false);
          setMainTabIndex(true);
        }
      } catch (error) {
        console.log(error, "erro");
        setPageLoading(false);
        if (error.status == 401) {
          setCustomErrorToast("長時間操作がなかったため、セッションが切れました。再度ログインしてください。");
        } else {
          setCustomErrorToast("問題が発生しました");
        }
        
        setMainTabIndex(false);
      }
    }
  };
  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form.getFieldValue]);


  const showPreview = () => {
    const currentFormValues = form.getFieldsValue();
    setFormValues({
      ...currentFormValues,
      businessHours: `${businessHours.startHour}:${businessHours.startMinute}~${businessHours.endHour}:${businessHours.endMinute}`,
      shopVacations: [{ start: vacationStart, end: vacationEnd }],
      shopHolidays: result,
      billingDataInvoices: [
        {
          zipCode: billingPostalCode,
          billingPrefecture: billlingZipCodeResult?.pref,
          billingMunicipalities: billlingZipCodeResult?.town,
          billingAddress: copyData.Address,
          billingDepartment: currentFormValues.billingDepartment,
          pic: currentFormValues.pic,
          billingEmails: [{ email: currentFormValues.billingEmails }],
        },
      ],
      prefectures: zipCodeResult?.pref,
      manicipalities: zipCodeResult?.town,
      shop: shop,
      shopImageUrl: fileInfo
        ? URL.createObjectURL(file)
        : shop?.shopImageUrl || null,
    });
    setPreviewVisible(true);
  };

  const closePreview = () => {
    setPreviewVisible(false);
  };


  if (loading || pageLoading) return <TableLoadingSkeleton />;
  return (
    <div className=" w-full h-full 0  ">
      <h1 className=" text-white text-center py-1 mb-1 bg-[#e95412]">
        買取店登録
      </h1>
      <Form
        onFinish={onFinish}
        className=" flex flex-col gap-0 w-full h-full overflow-y-auto"
        initialValues={initialValues}
        form={form}
      >
        <Row gutter={[0, 0]} className=" w-full h-full ">
          <Col span={16} className=" w-full h-full pr-4">
            <FormItem
              fullWidth
              layout="horizontal"
              className="  text-white w-full "
              name="companyName"
            >
              <Input
                name="companyName"
                label="会社名"
                className="w-full bg-[#fff9c5] py-2"
                defaultValue={shop?.companyName}
                required
              />
            </FormItem>
            <FormItem
              fullWidth
              layout="horizontal"
              className="  text-white w-full "
              name="name"
            >
              <Input
                required
                label="店名"
                className="w-full bg-[#fff9c5] py-2"
              />
            </FormItem>
            <Row className=" w-full m-0 p-0" gutter={[10, 0]}>
              <Col span={12} className="">
                <FormItem
                  fullWidth
                  layout="horizontal"
                  className=" custom-form-item text-white w-full "
                  name="shopTypeEnum"
                  rules={[
                    {
                      required: true,
                      message: "このフィールドは必須です",
                    },
                  ]}
                >
                  <Select
                    options={branchTypes}
                    fullWidth
                    label="組織区分"
                    className="h-10 w-full bg-[#fff9c5]"
                    onChange={(input, option) =>
                      setSelectbranchType(option.value)
                    }
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  fullWidth
                  layout="horizontal"
                  className="  text-white w-full "
                  name="postalCode"
                  rules={[
                    {
                      pattern: /^\d{3}-\d{4}$|^\d{7}$/,
                      message:
                        "郵便番号はXXX-XXXXまたはXXXXXXの形式で入力してください",
                    },
                  ]}
                >
                  <Input
                    label="郵便番号"
                    className="w-full  bg-[#fff9c5] py-2"
                    onChange={(e) => {
                      const value = e.target.value;
                      setCopyData({ ...copyData, postCode: value });
                      setPasteDataCheck(false);
                      setEditPasteDataCheck(false);
                      setZipCode(value);
                    }}
                    required
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  fullWidth
                  layout="horizontal"
                  className="custom-form-item  text-white w-full "
                  name="headBranchId"
                  rules={[
                    {
                      required: selectBranchType === "SUB_BRANCH",
                      message: "このフィールドは必須です",
                    },
                  ]}
                >
                  <Select
                    options={headBranchoptions}
                    fullWidth
                    label="本店選択"
                    className="h-10 w-full bg-[#fff9c5]"
                    disable={selectBranchType === "HEAD_BRANCH"}
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  fullWidth
                  layout="horizontal"
                  className="  text-white w-full "
                >
                  <Input
                    label="都道府県"
                    className="w-full bg-[#fff9c5] py-2"
                    value={zipCodeResult?.pref}
                    onChange={(e) => {
                      setCopyData({ ...copyData, prefectures: e.target.value });
                      setPasteDataCheck(false);
                    }}
                    required
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  fullWidth
                  layout="horizontal"
                  className=" custom-form-item text-white w-full "
                  name="storeSubscriptionType"
                  rules={[
                    {
                      required: true,
                      message: "このフィールドは必須です",
                    },
                  ]}
                >
                  <Select
                    options={shopTypes}
                    fullWidth
                    label="店舗種別"
                    className="h-10 w-full bg-[#fff9c5]"
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  fullWidth
                  layout="horizontal"
                  className="  text-white w-full "
                >
                  <Input
                    label="市区町村"
                    className="w-full bg-[#fff9c5] py-2"
                    value={zipCodeResult?.town}
                    onChange={(e) => {
                      setCopyData({
                        ...copyData,
                        municipalities: e.target.value,
                      });
                      setPasteDataCheck(false);
                    }}
                    required
                  />
                </FormItem>
              </Col>

              <Col span={12}>
                <FormItem
                  fullWidth
                  layout="horizontal"
                  className="  text-white w-full "
                  name="cancellationCategory"
                >
                  <Select
                    options={cancellationCategory}
                    fullWidth
                    label="解約区分"
                    className="h-10 w-full bg-[#fff9c5]"
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem
                  fullWidth
                  layout="horizontal"
                  className="  text-white w-full "
                  name="address"
                >
                  <Input
                    label="番地等"
                    className="w-full bg-[#fff9c5] py-2"
                    onChange={(e) => {
                      setCopyData({ ...copyData, Address: e.target.value });
                      setPasteDataCheck(false);
                      setEditPasteDataCheck(false);
                    }}
                    required
                  />
                </FormItem>
              </Col>
            </Row>
            <FormItem
              fullWidth
              layout="horizontal"
              className=" custom-form-item-2 text-white "
              name="email"
              rules={[
                {
                  type: "email",
                  message: "メールアドレスの形式が正しくありません",
                },
              ]}
            >
              <Input
                label="メール"
                className="w-1/4 bg-[#fff9c5] py-2"
                required
              />
            </FormItem>
            <FormItem
              fullWidth
              layout="horizontal"
              className="  text-white w-full "
              name="phone"
              rules={[
                {
                  pattern: /^(\+?[1-9]\d{1,14}|0\d{1,4}-\d{1,4}-\d{4})$/,
                  message:
                    "有効な電話番号を入力してください。例: +819012345678 または 090-1234-5678",
                },
              ]}
            >
              <Input
                label="電話"
                className="w-1/4 bg-[#fff9c5] py-2"
                required
              />
            </FormItem>
            <FormItem
              fullWidth
              layout="horizontal"
              className="  text-white w-full "
              name="appealStatement"
            >
              <Input
                label="アピール文"
                className="w-full truncate bg-[#fff9c5] py-2"
              />
            </FormItem>
            <div className="flex items-center">
              <label className="!bg-[#587c94] text-sm border-b-[1px] border-white text-white py-2 font-bold w-[7rem] px-2 mr-2">
                営業時間
              </label>
              <FormItem
                fullWidth
                layout="horizontal"
                className="text-white flex-1 flex items-center"
                name="businessHours"
              >
                <div className="flex items-center">
                  <Select
                    options={hourOptions}
                    value={businessHours.startHour}
                    onChange={(value) =>
                      setBusinessHours((prev) => ({
                        ...prev,
                        startHour: value,
                      }))
                    }
                    className="w-20 mr-1 bg-[#fff9c5]"
                  />
                  <span className="mx-1 text-lg  text-black">:</span>

                  <Select
                    options={minuteOptions}
                    value={businessHours.startMinute}
                    onChange={(value) =>
                      setBusinessHours((prev) => ({
                        ...prev,
                        startMinute: value,
                      }))
                    }
                    className="w-20 ml-1 bg-[#fff9c5]"
                  />
                  <span className="mx-1 text-lg  text-black">~</span>
                  <Select
                    options={hourOptions}
                    value={businessHours.endHour}
                    onChange={(value) =>
                      setBusinessHours((prev) => ({ ...prev, endHour: value }))
                    }
                    className="w-20 mr-1 bg-[#fff9c5]"
                  />
                  <span className="mx-1 text-lg  text-black">:</span>

                  <Select
                    options={minuteOptions}
                    value={businessHours.endMinute}
                    onChange={(value) =>
                      setBusinessHours((prev) => ({
                        ...prev,
                        endMinute: value,
                      }))
                    }
                    className="w-20 ml-1 bg-[#fff9c5]"
                  />
                </div>
              </FormItem>
            </div>

            <FormItem
              fullWidth
              layout="horizontal"
              className="  text-white w-full "
              name="shopImageUrl"
            >
              <Uploader
                label="店舗画像"
                fileInfo={fileInfo}
                setFileInfo={setFileInfo}
                file={file}
                setFile={setFile}
                edit={editRegisterFormStatus}
                url={shop ? shop?.shopImageUrl : undefined}
              />
            </FormItem>

            <div className=" w-full flex items-start gap-2 ">
              <label
                className="!bg-[#587c94] text-sm border-b-[1px] border-white text-white py-6 font-bold w-[7rem] px-2"
                htmlFor=""
              >
                独占／非独占
              </label>
              <FormItem
                fullWidth
                layout="horizontal"
                name="shopExclusivity"
                className=" custom-form-item  text-white w-full  flex !items-center "
                rules={[
                  {
                    required: true,
                    message: "このフィールドは必須です",
                  },
                ]}
              >
                <Radio
                  optionType="default"
                  options={exclusiveOptions}
                  label="営業時間"
                  className="w-full  mt-4"
                  onChange={(e) => setSelectedShopExclusivety(e.target.value)}
                />
              </FormItem>
            </div>
            <FormItem
              fullWidth
              layout="horizontal"
              className="  text-white w-full "
              name="exIntroductionFee"
            >
              <Input
                required
                label="紹介料(独占)"
                className="w-1/4 bg-[#fff9c5] py-2"
                disabled={selectedShopExclusivety == ""}
              />
            </FormItem>
            <FormItem
              fullWidth
              layout="horizontal"
              className="  text-white w-full "
              name="nonExReferralFee"
            >
              <Input
                label="紹介料(非独占)"
                className="w-1/4 bg-[#fff9c5] py-2"
                disabled={
                  selectedShopExclusivety == "EX" ||
                  selectedShopExclusivety == ""
                }
                required
              />
            </FormItem>
            <div className=" w-full flex items-start gap-2 ">
              <label
                className="!bg-[#587c94] text-sm border-b-[1px] border-white text-white py-6 font-bold w-[7rem] px-2"
                htmlFor=""
              >
                支払方法
              </label>
              <FormItem
                fullWidth
                layout="horizontal"
                className="   text-white w-full "
                name="paymentType"
                rules={[
                  {
                    required: true,
                    message: "このフィールドは必須です",
                  },
                ]}
              >
                <Radio
                  optionType="default"
                  options={paymentMethods}
                  label="営業時間"
                  className="w-full  py-2"
                />
              </FormItem>
            </div>
            <div className="flex items-center">
              <label
                htmlFor=""
                className="text-sm block font-semibold py-4 px-2  !bg-[#587c94] border-b-[1px] border-white text-white min-w-[7rem]"
              >
                休暇期間
              </label>
              <div className=" w-1/3 px-2  flex items-center justify-between gap-2">
                <FormItem
                  fullWidth
                  layout="horizontal"
                  className="  text-white w-full "
                  name="startDate"
                >
                  <DateTimePicker
                    time={vacationStart}
                    setTime={setVactionStart}
                    initialValue={shop ? shop?.shopVacations[0]?.start : null}
                  />
                </FormItem>
                <div>~</div>
                <FormItem
                  fullWidth
                  layout="horizontal"
                  className="  text-white w-full"
                  name="endDate"
                >
                  <DateTimePicker
                    time={vacationEnd}
                    minDate={vacationStart}
                    setTime={setVactionEnd}
                    initialValue={shop ? shop?.shopVacations[0]?.end : null}
                  />
                </FormItem>
              </div>
            </div>
            <div className=" w-full flex items-start gap-4 h-full !bg-[#587c94] ">
              <label
                className=" text-sm min-w-fit pr-[2.8rem] text-white  font-bold  px-2   "
                htmlFor=""
              >
                定休日
              </label>
              <div className=" w-full flex flex-col items-start group gap-2 h-full bg-white px-2">
                <FormItem
                  fullWidth
                  layout="horizontal"
                  className="  text-white w-full "
                  name="holidayMatching"
                >
                  <Checkbox
                    type="single"
                    value={isChecked || (shop && shop.holidayMatching)}
                    onChange={handleCheckboxChange}
                    label="定休日にマッチングしない"
                    typeCheck={false}
                  />
                </FormItem>
                <div className=" w-full ">
                  <FormItem
                    fullWidth
                    layout="horizontal"
                    className="  text-white w-full "
                  >
                    <Inputcard
                      dependancies={shop}
                      results={result}
                      defaultValues={defaultResult}
                      setResult={setResult}
                      setDefaultValues={setDefaultResult}
                    />
                  </FormItem>
                </div>
              </div>
            </div>
          </Col>
          <Col span={8} className="w-full">
            <div className=" w-full flex items-start gap-2 ">
              <label
                className="!bg-[#587c94] text-sm border-b-[1px] border-white  text-white  py-4 font-bold w-[7rem] px-2"
                htmlFor=""
              >
                請求先情報
              </label>
              <div className=" flex items-center justify-center  flex-1">
                <Button className=" w-full" onClick={pasteData}>
                  買取店住所 をコピー{" "}
                </Button>
              </div>
            </div>
            <FormItem
              fullWidth
              layout="horizontal"
              className="text-white w-full"
            >
              <Input
                value={inputValues.postCode}
                label="郵便番号"
                className="w-full bg-[#fff9c5] py-2"
                onChange={handleChange("postCode")}
              />
              {zipCodeError && billingPostalCode?.length > 1 && (
                <h1 className=" text-red-600 py-1">
                  郵便番号はXXX-XXXXまたはXXXXXXの形式で入力してください。
                </h1>
              )}
            </FormItem>
            <FormItem
              fullWidth
              layout="horizontal"
              className="text-white w-full"
            >
              <Input
                value={billlingZipCodeResult?.pref}
                label="都道府県"
                className="w-full bg-[#fff9c5] py-2"
                onChange={handleChange("prefectures")}
              />
            </FormItem>
            <FormItem
              fullWidth
              layout="horizontal"
              className="text-white w-full"
            >
              <Input
                value={billlingZipCodeResult?.town}
                label="市区町村"
                className="w-full bg-[#fff9c5] py-2"
                onChange={handleChange("municipalities")}
              />
            </FormItem>
            <FormItem
              fullWidth
              layout="horizontal"
              className="text-white w-full"
            >
              <Input
                value={inputValues.Address}
                label="番地等"
                className="w-full bg-[#fff9c5] py-2"
                onChange={handleChange("Address")}
              />
            </FormItem>
            <FormItem
              fullWidth
              layout="horizontal"
              className="  text-white w-full "
              name="billingDepartment"
            >
              <Input label="部署" className="w-full bg-[#fff9c5] py-2" />
            </FormItem>
            <FormItem
              fullWidth
              layout="horizontal"
              className="  text-white w-full "
              name="pic"
            >
              <Input label="担当者様" className="w-full bg-[#fff9c5] py-2" />
            </FormItem>
            <FormItem
              fullWidth
              layout="horizontal"
              className=" custom-form-item-2 text-white w-full "
              name="billingEmails"
              rules={[
                {
                  type: "email",
                  message: "メールアドレスの形式が正しくありません",
                },
              ]}
            >
              <Input label="メール" className="w-full bg-[#fff9c5] py-2" />
            </FormItem>
          </Col>
        </Row>
        <div className="flex justify-between mt-4">
          <Button
            htmlType="submit"
            className=" bg-orange-500 text-white mt-4 px-12 py-4 mx-2 rounded-md"
          >
            {shopId ? "更新" : "登録する"}
          </Button>
          <Button
            onClick={showPreview}
            className="bg-orange-500 text-white mt-4 px-12 py-4 mx-2 rounded-md"
          >
            プレビューする
          </Button>
        </div>
      </Form>
      <PreviewScreen
        visible={previewVisible}
        onClose={closePreview}
        formData={formValues}
      />
    </div>
  );
};
