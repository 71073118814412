import React, { useCallback, useEffect, useState } from "react";
import {
  useGetAppraisalsQuery,
  useLazyGetAppraisalsQuery,
} from "../../app/services/appraisals.api";
import AppraisalsTable from "./table";
import FilterSectionAppraisals from "./filter-section";
import { filterAppraisalsParams } from "../../utils/filterAppraisalsParams";
import { TableLoadingSkeleton } from "../../components";
import { Box } from "@mui/material";
import { useLazyGetFilterCSVAppraisalsQuery } from "../../app/services/fileControlls.api";

const AppraisalsLayout = () => {
  const [appraisalsQueryParams, setAppraisalsQueryParams] = useState({
    not_compatible: false,
    processing: false,
    appointment: false,
    closed: false,
    unsent: false,
    submit_an_application: false,
    recognition_applied: false,
    recognition_not_accepted: false,
    cancelled: false,
    seo: false,
    paid: false,
    paid2: false,
    paid3: false,
    paid4: false,
    phone: false,
    phone_no: "",
    isTestData: false,
    kw: "",
    offset: 0,
    limit: 20,
    to: null,
    from: null,
  });

  const [fetchAppraisalsFunc, { data, isLoading }] =
    useLazyGetAppraisalsQuery();

    const [fetchCSVAppraisals, { data: csvData }] = useLazyGetFilterCSVAppraisalsQuery();
    const refreshData = useCallback(() => {
      const requestedQueryParams = filterAppraisalsParams(appraisalsQueryParams);
      fetchAppraisalsFunc(requestedQueryParams);
    }, [appraisalsQueryParams, fetchAppraisalsFunc]);

  useEffect(() => {
    const requestedQueryParams = filterAppraisalsParams(appraisalsQueryParams);
    fetchAppraisalsFunc(requestedQueryParams);
  }, [
    appraisalsQueryParams.not_compatible,
    appraisalsQueryParams.appointment,
    appraisalsQueryParams.processing,
    appraisalsQueryParams.closed,
    appraisalsQueryParams.unsent,
    appraisalsQueryParams.submit_an_application,
    appraisalsQueryParams.recognition_applied,
    appraisalsQueryParams.recognition_not_accepted,
    appraisalsQueryParams.cancelled,
    appraisalsQueryParams.seo,
    appraisalsQueryParams.paid,
    appraisalsQueryParams.paid2,
    appraisalsQueryParams.paid3,
    appraisalsQueryParams.paid4,
    appraisalsQueryParams.phone,
    appraisalsQueryParams.isTestData,
  ]);

  const onChangePagination = (pageIndex, pageSize) => {
    let requestedQueryParams = filterAppraisalsParams(appraisalsQueryParams);
    requestedQueryParams.limit = pageSize;
    requestedQueryParams.offset = pageIndex * pageSize;
    fetchAppraisalsFunc(requestedQueryParams);
  };

  const onClickSearch = () => {
    let requestedQueryParams = filterAppraisalsParams(appraisalsQueryParams);
    fetchAppraisalsFunc(requestedQueryParams);
  };

  const onClickCSV = async () => {
    let requestedQueryParams = filterAppraisalsParams(appraisalsQueryParams);
    const response = await fetchCSVAppraisals(requestedQueryParams);
    if (response.data && response.data.url) {
      return response.data.url;
    }
    return null;
  }

  const onClickClear = (appraisalsClearParams) => {
    let requestedQueryParams = filterAppraisalsParams(appraisalsClearParams);
    fetchAppraisalsFunc(requestedQueryParams);
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '50vh' }}>
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          zIndex:1,
          backgroundColor: 'white',
          boxShadow: '0 2px 0px rgba(0,0,0,0.1)'
        }}
      >
      <FilterSectionAppraisals
        appraisalsQueryParams={appraisalsQueryParams}
        rowCount={data && data.allCount ? data.allCount : 0}
        setAppraisalsQueryParams={setAppraisalsQueryParams}
        onClickSearch={onClickSearch}
        onClickClear={onClickClear}
        onClickCSV={onClickCSV}
      />
      </Box>
      {data && !isLoading ? (
        <AppraisalsTable
          data={data.operatorAppraisalList ? data.operatorAppraisalList : []}
          rowCount={data.allCount ? data.allCount : 0}
          onChangePagination={onChangePagination}
          refreshData={refreshData}
        />
      ) : (
        <TableLoadingSkeleton />
      )}
    </Box>
  );
};

export default AppraisalsLayout;
