import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Box,
  Tab,
  Tabs,
  Typography,
  TextField,
  Button,
  ButtonGroup,
  Stack,
  CircularProgress,
} from "@mui/material";
import PropTypes from "prop-types";
import "./index.css";
import { PurchaseStoresListLayout } from "../../layouts";
import { NavigatePage, MainWrapper } from "../../components";
import AppraisalsLayout from "../../layouts/appraisals";
import { BuyerRegisterForm } from "../../components/adminPortalComponents/buyerRegister/buyerRegisterForm";
import { MatchingConditionForm } from "../../components/adminPortalComponents/matchingCondition/matchingConditionForm";
import { useAppStore } from "../../stores/app.store";
import NoticeLayout from "../../layouts/notices";
import { useLocation } from "react-router-dom";
import RejectedApplications from "../../layouts/rejected_applications";
import EditHistoryLayout from "../../layouts/edit-history";
import ImprovementRequest from "../../layouts/improvement-request";
import axios from "axios";
import { styled } from "@mui/material/styles";
import CarManufacturer from "../../components/CarManufacturer/CarManufacturer";
import generateAndDownloadCSV from "../../utils/generate-csv-template";
import BasicModal from "../../components/Model";
import DataListTable from "../../components/Table";
import CreateVehicle from "./create";
import Publish from "../synchronization/publishButton";
import {
  useGetVehicleBrandInfoQuery,
  useLazyGetJobStatusQuery,
  useLazyGetVehicleBrandInfoQuery,
  useLazyGetVehicleBrandsQuery,
  usePostWpJsonGenMutation,
} from "../../app/services/vehicleBrand.api";
import { set } from "lodash";
import { useGetKeyValueQuery } from "../../app/services/keyValueApi ";

const HorizontalScrollBox = styled(Box)(({ theme }) => ({
  display: "flex",
  overflowX: "auto",
  width: "100%",
  "&::-webkit-scrollbar": {
    width: 8, // Adjust width as needed
    height: 8, // Adjust height as needed
    background: theme.palette.grey[300], // Grey background
  },
  "&::-webkit-scrollbar-thumb": {
    background: theme.palette.primary.main, // Orange scroller (assumes primary is orange)
    borderRadius: "10px", // Rounded edges
  },
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function AdminPortal() {
  const [value, setValue] = React.useState(0);
  const { state } = useLocation();
  const [open, setOpen] = useState(false);
  const [vehicleOpen, setVehicleOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleOpenCreateVehicle = () => setVehicleOpen(true);
  const handleCloseCreateVehicle = () => {
    setVehicleOpen(false)
    // setShouldTriggerUpdateCheck(true);
    setNewVehicleCreated(true);
  };
  const handleClose = () => setOpen(false);
  const [allItems, setItems] = useState(null);
  const [allDataCount, setAllDataCount] = useState(null);
  const [allBrands, setBrands] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchTextYear, setSearchTextYear] = useState("");
  const [searchText2, setSearchText2] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isManufacturerLoading, setIsManufacturerLoading] = useState(true);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [postWpJson] = usePostWpJsonGenMutation();
  const [isDownloading, setIsDownloading] = useState(false);
  const [checkJobStatus] = useLazyGetJobStatusQuery();
  const [newVehicleCreated, setNewVehicleCreated] = useState(false);


  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [shouldTriggerUpdateCheck, setShouldTriggerUpdateCheck] = useState(false);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const { data: vehicleUpdateStatus, refetch } = useGetKeyValueQuery(
    "isVehicleJsonHaveUpdates",
    {
      skip: !shouldTriggerUpdateCheck,
      refetchOnMountOrArgChange: shouldTriggerUpdateCheck
    }
  );

  useEffect(() => {
    if (newVehicleCreated) {
      const timer = setTimeout(() => {
        setNewVehicleCreated(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [newVehicleCreated]);

  useEffect(() => {
    if (shouldTriggerUpdateCheck) {
      setShouldTriggerUpdateCheck(false);
    }
  }, [shouldTriggerUpdateCheck]);

  const handleSearch = () => {
    setPage(0);
    setIsLoading(true);
    retriveAllItems(0, pageSize, searchText, searchTextYear);
  };

  const handleSearchYear = () => {
    setPage(0);
    setIsLoading(true);
    retriveAllItems(0, pageSize, searchText, searchTextYear);
  };

  const handlePaginationModelChange = (newModel) => {
    setIsLoading(true);
    // setPaginationModel(newModel);
    retriveAllItems(
      newModel.page,
      newModel.pageSize,
      searchText,
      searchTextYear
    );
  };

  const handleSearch2 = () => {
    retriveAllBrands(searchText2);
  };

  const [getBrands] = useLazyGetVehicleBrandsQuery();
  // const {
  //   data: vehicleBrandInfo,
  //   isLoading2,
  //   isFetching,
  // } = useGetVehicleBrandInfoQuery(
  //   {
  //     skip: paginationModel.page * paginationModel.pageSize,
  //     limit: paginationModel.pageSize,
  //     search: encodeURIComponent(searchText),
  //   },
  //   {
  //     // This ensures the query is refetched when these values change
  //     skip: !isInitialLoad && searchText !== "",
  //     refetchOnMountOrArgChange: true,
  //   }
  // );

  const [getVehicleBrandInfo] = useLazyGetVehicleBrandInfoQuery();

  useEffect(() => {
    retriveAllBrands("");
    setIsManufacturerLoading(true);
  }, []);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        await getBrands("").unwrap();
        await retriveAllItems(0, paginationModel.pageSize, "", "");
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsInitialLoad(false);
      }
    };

    if (isInitialLoad) {
      fetchInitialData();
    }
  }, [getBrands, isInitialLoad, paginationModel.pageSize]);

  const retriveAllItems = (page, limit, search, searchYear) => {
    const skip = page * limit;
    if (search === undefined || search === null) search = "";
    if (searchYear === undefined || searchYear === null) searchYear = "";

    const base64EncodedString = encodeURIComponent(search);
    const base64EncodedStringYear = encodeURIComponent(searchYear);
    setIsLoading(true);

    getVehicleBrandInfo({
      skip,
      limit,
      search: base64EncodedString,
      searchYear: base64EncodedStringYear,
    })
      .unwrap()
      .then((res) => {
        setAllDataCount(res?.count);
        setItems(res?.data);
        setIsLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  };

  const retriveAllBrands = (search) => {
    const originalString = search;
    setIsManufacturerLoading(true);
    const base64EncodedString = encodeURIComponent(originalString);
    getBrands(base64EncodedString)
      .unwrap()
      .then((res) => {
        setBrands(res);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => setIsManufacturerLoading(false));
  };
  const refreshHBar = () => {
    retriveAllBrands("");
    setShouldTriggerUpdateCheck(true);
  };

  const refreshTable = (props) => {
    if (props != null || props != undefined || props != "") {
      retriveAllItems(0, 10, props);
    } else {
      retriveAllItems(0, 10, "");
    }
    setShouldTriggerUpdateCheck(true);
  };

  useEffect(() => {
    if (state && state.selectedTabDashboard) {
      setValue(state.selectedTabDashboard);
    }
  }, [state]);

  const {
    formTabIndex,
    setFormTabIndex,
    setEditRegisterFormStatus,
    setMainTabIndex,
    mainTabIndex,
    setMatchingConditonTabIndex,
    matchingCondtionTabIndex,
    setIsTabPageAction,
  } = useAppStore();

  // Handle tab change
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setIsTabPageAction(false);

    // Update URL without reloading the page
    window.history.pushState({}, "", `?tab=${newValue}`);
  };

  useEffect(() => {
    // Read tab index from URL when component mounts
    const queryParams = new URLSearchParams(window.location.search);
    const tabIndex = parseInt(queryParams.get("tab") || "0", 10);
    setValue(tabIndex);

    const handlePopState = (event) => {
      const newTabIndex = parseInt(
        new URLSearchParams(window.location.search).get("tab") || "0",
        10
      );
      setValue(newTabIndex);
    };

    // Add event listener for popstate to handle browser back/forward buttons
    window.addEventListener("popstate", handlePopState);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useEffect(() => {
    if (value !== 6) {
      setFormTabIndex(false);
    }
    if (formTabIndex) {
      handleChange("", 6);
      setIsTabPageAction(true);
    }

    if (value !== 7) {
      setMatchingConditonTabIndex(false);
    }
    if (matchingCondtionTabIndex) {
      handleChange("", 7);
      setIsTabPageAction(true);
    }

    if (value !== 0) {
      setMainTabIndex(false);
    }
    if (mainTabIndex) {
      handleChange("", 0);
    }
  }, [formTabIndex, matchingCondtionTabIndex, mainTabIndex, value]);

  const handleGenerateAndDownloadCSV = async () => {
    setIsDownloading(true);

    try {
      const fileData = {
        bucketName: "ctn-uploads",
        keyName: `generateVehicleCSV_${Date.now()}.csv`,
      };

      const jobResponse = await postWpJson(fileData).unwrap();
      console.log("Job started:", jobResponse);

      const jobId = jobResponse.jobId;
      let completed = false;
      let fileUrl = null;
      const pollingInterval = 30000;
      const maxTotalTime = 7 * 60 * 1000;
      const startTime = Date.now();

      while (!completed) {
        if (Date.now() - startTime >= maxTotalTime) {
          throw new Error("CSV generation is taking longer than expected. Please check back later.");
        }

        await new Promise(resolve => setTimeout(resolve, pollingInterval));

        const statusResponse = await checkJobStatus(jobId).unwrap();
        console.log("Job status:", statusResponse);

        if (statusResponse.status === "COMPLETED") {
          completed = true;
          fileUrl = statusResponse.url;
        } else if (statusResponse.status === "FAILED") {
          throw new Error(statusResponse.error || "Job failed");
        }
      }

      const fileResponse = await fetch(fileUrl);
      if (!fileResponse.ok) {
        throw new Error(`Failed to download file: ${fileResponse.status} ${fileResponse.statusText}`);
      }

      const fileBlob = await fileResponse.blob();
      const downloadUrl = URL.createObjectURL(fileBlob);

      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = "vehicle_data.csv";
      document.body.appendChild(link);
      link.click();

      URL.revokeObjectURL(downloadUrl);
      document.body.removeChild(link);
      setShouldTriggerUpdateCheck(true);
    } catch (e) {
      console.error("Error generating or downloading file:", e);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <MainWrapper>
      <NavigatePage />
      <div className="tabs-wrapper">
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="車種一覧" {...a11yProps(0)} />
              <Tab label="買取店一覧" {...a11yProps(1)} />
              <Tab label="査定依頼一覧" {...a11yProps(2)} />
              <Tab label="お知らせ" {...a11yProps(3)} />
              <Tab label="却下申請一覧" {...a11yProps(4)} />
              <Tab label="システム改善要望一覧" {...a11yProps(5)} />
              <Tab sx={{ display: "none" }} {...a11yProps(6)} />
              <Tab sx={{ display: "none" }} {...a11yProps(7)} />
              <Tab label="変更履歴一覧" {...a11yProps(8)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <div className="tab-content">
              <div className="action-bar-wrapper">
                <div className="">
                  <Grid container spacing={2} columns={3}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={1}>
                      <Box sx={{ pt: 1, pb: 1, textAlign: "center" }}>
                        {/* Adjust spacing as needed */}
                        <div>
                          <Typography variant="h4" sx={{ mb: 1 }}>
                            <strong>メーカー一覧</strong>
                          </Typography>
                        </div>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      sx={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Stack direction={"row"} spacing={2}>
                        <TextField
                          id="outlined-basic"
                          label="検索"
                          variant="outlined"
                          size="small"
                          placeholder="メーカーで検索"
                          color="warning"
                          onChange={(e) => setSearchText2(e.target.value)}
                        />
                        <Button
                          className="search-btn"
                          onClick={handleSearch2}
                          variant="contained"
                        >
                          検索
                        </Button>
                        <Publish newVehicleCreated={newVehicleCreated} />
                      </Stack>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} columns={16}>
                    {isManufacturerLoading ? (
                      <Grid item xs={16}>
                        <div
                          style={{
                            width: "100%",
                            height: "100px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          ローディング...
                        </div>
                      </Grid>
                    ) : (
                      <>
                        <Grid item xs={16}>
                          <HorizontalScrollBox style={{ overflowY: "hidden" }}>
                            {allBrands?.length !== 0 ? (
                              <div style={{ display: "flex", gap: "6px" }}>
                                {allBrands.map((item, index) => (
                                  <CarManufacturer
                                    key={index}
                                    imgLink={item.logo}
                                    brand={item.brand}
                                    id={item.id}
                                    matt={item.matt}
                                    refreshHBar={refreshHBar}
                                  />
                                ))}
                              </div>
                            ) : (
                              // Render content once data is ready

                              // Render a message if there is no data
                              <div
                                style={{
                                  width: "100%",
                                  height: "100px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  backgroundColor: "#f0f0f0",
                                  color: "#888",
                                  borderRadius: "5px",
                                  margin: "10px 0",
                                }}
                              >
                                検索結果がありません
                              </div>
                            )}
                          </HorizontalScrollBox>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </div>
                <Grid
                  container
                  spacing={1}
                  justifyContent="space-between"
                  style={{ paddingTop: "10px" }}
                >
                  <Grid item xs={5}>
                    <div className="search-wrapper">
                      <Stack direction={"row"} spacing={2}>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          label="検索"
                          variant="outlined"
                          size="small"
                          placeholder="メーカー、車種、年式、グレードによる検索が可能"
                          color="warning"
                          onChange={(e) => setSearchText(e.target.value)}
                        />
                        <Button
                          className="search-btn"
                          onClick={handleSearch}
                          variant="contained"
                        >
                          検索
                        </Button>
                      </Stack>
                      {/* <Stack direction={"row"} spacing={2} marginTop={"10px"}>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          label="年式"
                          variant="outlined"
                          size="small"
                          placeholder="年式"
                          color="warning"
                          onChange={(e) => setSearchTextYear(e.target.value)}
                        />
                        <Button
                          className="search-btn"
                          onClick={handleSearchYear}
                          variant="contained"
                        >
                          適用
                        </Button>
                      </Stack> */}
                    </div>
                  </Grid>
                  <Grid item xs={7}>
                    <Stack direction={"row"} justifyContent="flex-end">
                      <Button
                        onClick={generateAndDownloadCSV}
                        size="large"
                        sx={{ mr: "4px" }}
                        className="search-btn"
                        variant="contained"
                      >
                        CSVテンプレートをダウンロードする
                      </Button>
                      <Button
                        onClick={handleOpen}
                        size="large"
                        className="search-btn"
                        variant="contained"
                      >
                        CSVアップロード
                      </Button>

                      {/* file upload modal */}
                      <BasicModal
                        open={open}
                        onclose={handleClose}
                        acceptCSV={true}
                        refreshTable={refreshTable}
                        refreshHBar={refreshHBar}
                      />
                    </Stack>
                    <Stack
                      direction={"row"}
                      justifyContent="flex-end"
                      marginTop={"10px"}
                    >
                      <Button
                        onClick={handleOpenCreateVehicle}
                        size="large"
                        className="create-btn"
                        variant="contained"
                      >
                        新規作成
                      </Button>
                    </Stack>
                    <Stack
                      direction={"row"}
                      justifyContent="flex-end"
                      marginTop={"10px"}
                    >
                      <Button
                        onClick={handleGenerateAndDownloadCSV}
                        size="large"
                        className="create-btn"
                        variant="contained"
                        disabled={isDownloading}
                        startIcon={
                          isDownloading ? (
                            <CircularProgress size={20} color="inherit" />
                          ) : null
                        }
                      >
                        {isDownloading ? "処理中..." : "CSV出力"}
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
                <Stack
                  direction={"row"}
                  justifyContent="flex-end"
                  marginTop={"10px"}
                >
                  <div style={{ textAlign: "right" }}>
                    <Typography
                      variant="body1"
                      onClick={() =>
                        window.open("/csv-bulk-upload-guideline.pdf", "_blank")
                      }
                      style={{ cursor: "pointer" }}
                    >
                      CSVアップロードガイドはこちら
                    </Typography>
                  </div>
                </Stack>
              </div>
              <div className="table-wrapper">
                <DataListTable
                  allItems={allItems}
                  setItems={setItems}
                  dataCount={allDataCount}
                  setIsLoading={setIsLoading}
                  isLoading={isLoading}
                  page={page}
                  setPage={setPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  paginationModel={paginationModel}
                  setPaginationModel={handlePaginationModelChange}
                  refreshTable={refreshTable}
                  refreshHBar={refreshHBar}
                />
              </div>
            </div>
          </CustomTabPanel>

          <CustomTabPanel value={value} index={1}>
            <PurchaseStoresListLayout />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <AppraisalsLayout />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            <NoticeLayout />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={4}>
            <RejectedApplications />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={5}>
            <ImprovementRequest />
          </CustomTabPanel>

          {value === 6 && (
            <CustomTabPanel value={value} index={6}>
              <BuyerRegisterForm />
            </CustomTabPanel>
          )}
          {value === 7 && (
            <CustomTabPanel value={value} index={7}>
              <MatchingConditionForm />
            </CustomTabPanel>
          )}

          <CustomTabPanel value={value} index={8}>
            <EditHistoryLayout />
          </CustomTabPanel>
        </Box>
        <CreateVehicle
          isOpen={vehicleOpen}
          onClose={handleCloseCreateVehicle}
          refreshTable={refreshTable}
          refreshHBar={refreshHBar}
        />
      </div>
    </MainWrapper>
  );
}

export default AdminPortal;
