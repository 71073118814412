export function removeDuplicatesNested(data) {
  const uniqueHeaders = new Set();

  return data
    ?.map((item) => {
      if (!uniqueHeaders.has(item.value)) {
        uniqueHeaders.add(item.value);
        const uniqueValues = new Set(); // Reset for each top-level item

        // Filter out duplicates in children
        const filteredChildren = item?.children?.filter((child) => {
          if (!uniqueValues.has(child.value)) {
            uniqueValues.add(child.value);
            return true;
          }
          return false;
        });

        return {
          ...item,
          children: filteredChildren,
        };
      }
      return null;
    })
    .filter((item) => item !== null);
}

export function removeDuplicates(arr) {
  const seenValues = new Set();
  let keyCounter = 1; // Start key numbering from 1 (or any other starting number you prefer)

  return arr?.filter((item) => {
    if (!seenValues.has(item.value)) {
      seenValues.add(item.value);
      item.key = keyCounter.toString(); // Reassign key with a sequential number
      keyCounter++;
      return true;
    }
    return false;
  });
}
