import React from "react";
import { Card } from "antd";

const formatAreaValue = (areas) => {
  if (!areas || areas.length === 0) return "未設定";

  return areas
    .map((area) => {
      if (typeof area === "object" && area !== null) {
        if (area.prefectures) {
          return `${area.prefectures}${
            area.manicipalities !== "全域" ? ` ${area.manicipalities}` : ""
          }`;
        }
        if (area.name) {
          return area.name.replace(/--|\d+|false|全域/g, "").trim();
        }
        return "";
      }
      return typeof area === "string"
        ? area.replace(/--|\d+|false|全域/g, "").trim()
        : "";
    })
    .filter((area) => area)
    .join("、");
};

const DiffSection = ({
  title,
  beforeValue,
  afterValue,
  addedItems = [],
  removedItems = [],
}) => {
  return (
    <div className="mb-6">
      <h3 className="text-lg font-medium mb-4">{title}</h3>
      <div className="space-y-4">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <div className="text-sm text-gray-600 mb-2">変更前の選択肢:</div>
            <div className="bg-gray-50 p-3 rounded">{beforeValue}</div>
          </div>
          <div>
            <div className="text-sm text-gray-600 mb-2">変更後の選択肢:</div>
            <div className="bg-gray-50 p-3 rounded">{afterValue}</div>
          </div>
        </div>

        {addedItems?.length > 0 && (
          <div>
            <div className="text-sm text-gray-600 mb-2">追加された項目:</div>
            <div className="bg-green-50 p-3 rounded text-green-700">
              {Array.isArray(addedItems) ? addedItems.join("、") : addedItems}
            </div>
          </div>
        )}

        {removedItems?.length > 0 && (
          <div>
            <div className="text-sm text-gray-600 mb-2">削除された項目:</div>
            <div className="bg-red-50 p-3 rounded text-red-700">
              {Array.isArray(removedItems)
                ? removedItems.join("、")
                : removedItems}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const formatCarTypes = (carTypes) => {
  if (!carTypes || !carTypes.length) return "未設定";
  if (carTypes.some((ct) => ct.carType === "全て")) return "全て";
  return carTypes.map((ct) => `${ct.carMaker} - ${ct.carType}`).join("、");
};

const formatArray = (arr) => {
  if (!arr || arr.length === 0) return "未設定";
  return arr.join("、");
};

const formatEmailList = (emails) => {
  if (!emails || emails.length === 0) return "未設定";
  return emails
    .map((email) => email.email || email)
    .filter(Boolean)
    .join("、");
};

const compareArrays = (original = [], updated = []) => {
  if (!original) original = [];
  if (!updated) updated = [];

  const added = updated.filter((item) => !original.includes(item));
  const removed = original.filter((item) => !updated.includes(item));

  return {
    hasChanged: added.length > 0 || removed.length > 0,
    added,
    removed,
  };
};

const ChangesConfirmationModal = ({
  isOpen,
  onCancel,
  onConfirm,
  originalData,
  newData,
}) => {
  if (!isOpen) return null;

  const areValuesDifferent = (val1, val2) => {
    if (val1 === val2) return false;
    if (!val1 && !val2) return false;
    return String(val1 || "") !== String(val2 || "");
  };

  // Helper function to compare arrays of emails
  const compareEmails = (original = [], updated = []) => {
    const normalizeEmail = (email) => email.email || email;

    const originalEmails = original.map(normalizeEmail);
    const updatedEmails = updated.map(normalizeEmail);

    const added = updatedEmails.filter(
      (email) => !originalEmails.includes(email)
    );
    const removed = originalEmails.filter(
      (email) => !updatedEmails.includes(email)
    );

    return {
      hasChanged: added.length > 0 || removed.length > 0,
      added,
      removed,
    };
  };

  const compareAreas = (original = [], updated = []) => {
    if (!original) original = [];
    if (!updated) updated = [];

    const formatArea = (area) => {
      if (typeof area === "object" && area !== null) {
        if (area.prefectures) {
          return area.prefectures;
        }
      }
      return "";
    };

    const originalFormatted = original.map(formatArea);
    const updatedFormatted = updated.map(formatArea);

    const added = updated.filter((area) => {
      const formattedArea = formatArea(area);
      return !originalFormatted.includes(formattedArea);
    });

    const removed = original.filter((area) => {
      const formattedArea = formatArea(area);
      return !updatedFormatted.includes(formattedArea);
    });

    return {
      hasChanged: added.length > 0 || removed.length > 0,
      added: added.map(formatArea),
      removed: removed.map(formatArea),
    };
  };

  const compareMunicipalities = (original = [], updated = []) => {
    if (!original) original = [];
    if (!updated) updated = [];

    const formatMunicipality = (area) => {
      if (typeof area === "object" && area !== null) {
        if (
          area.prefectures &&
          area.manicipalities 
          // area.manicipalities !== "全域"
        ) {
          return `${area.prefectures} ${area.manicipalities}`;
        }
      }
      return "";
    };

    const originalFormatted = original.map(formatMunicipality).filter(Boolean);
    const updatedFormatted = updated.map(formatMunicipality).filter(Boolean);

    const added = updated
      .map(formatMunicipality)
      .filter((m) => m && !originalFormatted.includes(m));
    const removed = original
      .map(formatMunicipality)
      .filter((m) => m && !updatedFormatted.includes(m));

    return {
      hasChanged: added.length > 0 || removed.length > 0,
      added,
      removed,
    };
  };

  const compareCarTypes = (original = [], updated = []) => {
    if (!original) original = [];
    if (!updated) updated = [];

    const normalizeCarType = (ct) => `${ct.carMaker}-${ct.carType}`;

    const originalNormalized = original.map(normalizeCarType);
    const updatedNormalized = updated.map(normalizeCarType);

    const added = updated.filter(
      (ct) => !originalNormalized.includes(normalizeCarType(ct))
    );
    const removed = original.filter(
      (ct) => !updatedNormalized.includes(normalizeCarType(ct))
    );

    return {
      hasChanged: added.length > 0 || removed.length > 0,
      added,
      removed,
    };
  };

  const changes = {
    emails: compareEmails(originalData?.emails || [], newData?.emails || []),
    shopArea: compareAreas(
      originalData?.shopArea || [],
      newData?.shopArea || []
    ),
    municipalities: compareMunicipalities(
      originalData?.shopArea || [],
      newData?.shopArea || []
    ),
    carTypes: {
      ok: compareCarTypes(
        originalData?.okCarTypes || [],
        newData?.okCarTypes || []
      ),
      ng: compareCarTypes(
        originalData?.ngCarTypes || [],
        newData?.ngCarTypes || []
      ),
    },
    bodyTypes: {
      ok: compareArrays(
        originalData?.okBodyTypes || [],
        newData?.okBodyTypes || []
      ),
      ng: compareArrays(
        originalData?.ngBodyTypes || [],
        newData?.ngBodyTypes || []
      ),
    },
    makes: {
      ok: compareArrays(originalData?.okMakes || [], newData?.okMakes || []),
      ng: compareArrays(originalData?.ngMakes || [], newData?.ngMakes || []),
    },
    year: {
      ok: {
        hasChanged:
          areValuesDifferent(originalData?.yearOkFrom, newData?.yearOkFrom) ||
          areValuesDifferent(originalData?.yearOkTo, newData?.yearOkTo),
      },
      ng: {
        hasChanged:
          areValuesDifferent(originalData?.yearNgFrom, newData?.yearNgFrom) ||
          areValuesDifferent(originalData?.yearNgTo, newData?.yearNgTo),
      },
    },
    distance: {
      ok: {
        hasChanged:
          areValuesDifferent(
            originalData?.distanceOkFrom,
            newData?.distanceOkFrom
          ) ||
          areValuesDifferent(originalData?.distanceOkTo, newData?.distanceOkTo),
      },
      ng: {
        hasChanged:
          areValuesDifferent(
            originalData?.distanceNgFrom,
            newData?.distanceNgFrom
          ) ||
          areValuesDifferent(originalData?.distanceNgTo, newData?.distanceNgTo),
      },
    },
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <Card className="bg-white rounded-lg shadow-xl w-full max-w-4xl max-h-[90vh] overflow-y-auto">
        <div className="p-6">
          <h2 className="text-xl font-bold mb-6">変更内容の確認</h2>

          <div className="space-y-2">
            {changes.emails.hasChanged && (
              <DiffSection
                title="メールアドレス"
                beforeValue={formatEmailList(originalData?.emails)}
                afterValue={formatEmailList(newData?.emails)}
                addedItems={changes.emails.added}
                removedItems={changes.emails.removed}
              />
            )}

            {changes.shopArea.hasChanged && (
              <DiffSection
                title="対象エリア"
                beforeValue={formatAreaValue(
                  originalData?.shopArea?.map((area) => ({
                    ...area,
                    manicipalities: "全域",
                  }))
                )}
                afterValue={formatAreaValue(
                  newData?.shopArea?.map((area) => ({
                    ...area,
                    manicipalities: "全域",
                  }))
                )}
                addedItems={changes.shopArea.added}
                removedItems={changes.shopArea.removed}
              />
            )}

            {changes.municipalities.hasChanged && (
              <DiffSection
                title="対象都市"
                beforeValue={formatAreaValue(
                  originalData?.shopArea?.filter(
                    (area) => area.manicipalities !== "全域"
                  )
                )}
                afterValue={formatAreaValue(
                  newData?.shopArea
                )}
                addedItems={changes.municipalities.added}
                removedItems={changes.municipalities.removed}
              />
            )}
            {changes.carTypes.ok.hasChanged && (
              <DiffSection
                title="取扱車種 (OK)"
                beforeValue={formatCarTypes(originalData?.okCarTypes)}
                afterValue={formatCarTypes(newData?.okCarTypes)}
                addedItems={changes.carTypes.ok.added.map(
                  (ct) => `${ct.carMaker} - ${ct.carType}`
                )}
                removedItems={changes.carTypes.ok.removed.map(
                  (ct) => `${ct.carMaker} - ${ct.carType}`
                )}
              />
            )}

            {changes.carTypes.ng.hasChanged && (
              <DiffSection
                title="取扱車種 (NG)"
                beforeValue={formatCarTypes(originalData?.ngCarTypes)}
                afterValue={formatCarTypes(newData?.ngCarTypes)}
                addedItems={changes.carTypes.ng.added.map(
                  (ct) => `${ct.carMaker} - ${ct.carType}`
                )}
                removedItems={changes.carTypes.ng.removed.map(
                  (ct) => `${ct.carMaker} - ${ct.carType}`
                )}
              />
            )}

            {changes.bodyTypes.ok.hasChanged && (
              <DiffSection
                title="ボディタイプ (OK)"
                beforeValue={formatArray(originalData?.okBodyTypes)}
                afterValue={formatArray(newData?.okBodyTypes)}
                addedItems={changes.bodyTypes.ok.added}
                removedItems={changes.bodyTypes.ok.removed}
              />
            )}

            {changes.bodyTypes.ng.hasChanged && (
              <DiffSection
                title="ボディタイプ (NG)"
                beforeValue={formatArray(originalData?.ngBodyTypes)}
                afterValue={formatArray(newData?.ngBodyTypes)}
                addedItems={changes.bodyTypes.ng.added}
                removedItems={changes.bodyTypes.ng.removed}
              />
            )}

            {changes.makes.ok.hasChanged && (
              <DiffSection
                title="メーカー (OK)"
                beforeValue={formatArray(originalData?.okMakes)}
                afterValue={formatArray(newData?.okMakes)}
                addedItems={changes.makes.ok.added}
                removedItems={changes.makes.ok.removed}
              />
            )}

            {changes.makes.ng.hasChanged && (
              <DiffSection
                title="メーカー (NG)"
                beforeValue={formatArray(originalData?.ngMakes)}
                afterValue={formatArray(newData?.ngMakes)}
                addedItems={changes.makes.ng.added}
                removedItems={changes.makes.ng.removed}
              />
            )}

            {changes.year.ok.hasChanged && (
              <DiffSection
                title="年式 (OK)"
                beforeValue={`${originalData?.yearOkFrom || "未設定"} ～ ${
                  originalData?.yearOkTo || "未設定"
                }`}
                afterValue={`${newData?.yearOkFrom || "未設定"} ～ ${
                  newData?.yearOkTo || "未設定"
                }`}
              />
            )}

            {changes.year.ng.hasChanged && (
              <DiffSection
                title="年式 (NG)"
                beforeValue={`${originalData?.yearNgFrom || "未設定"} ～ ${
                  newData?.yearNgTo || "未設定"
                }`}
                afterValue={`${newData?.yearNgFrom || "未設定"} ～ ${
                  newData?.yearNgTo || "未設定"
                }`}
              />
            )}

            {changes.distance.ok.hasChanged && (
              <DiffSection
                title="走行距離 (OK)"
                beforeValue={`${originalData?.distanceOkFrom || "未設定"} ～ ${
                  originalData?.distanceOkTo || "未設定"
                }`}
                afterValue={`${newData?.distanceOkFrom || "未設定"} ～ ${
                  newData?.distanceOkTo || "未設定"
                }`}
              />
            )}

            {changes.distance.ng.hasChanged && (
              <DiffSection
                title="走行距離 (NG)"
                beforeValue={`${originalData?.distanceNgFrom || "未設定"} ～ ${
                  newData?.distanceNgTo || "未設定"
                }`}
                afterValue={`${newData?.distanceNgFrom || "未設定"} ～ ${
                  newData?.distanceNgTo || "未設定"
                }`}
              />
            )}
          </div>

          <div className="flex justify-end space-x-4 mt-8 pt-4 border-t">
            <button
              onClick={onCancel}
              className="px-6 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
            >
              キャンセル
            </button>
            <button
              onClick={onConfirm}
              className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
            >
              変更を確定する
            </button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ChangesConfirmationModal;
