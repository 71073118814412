import { useState, useEffect } from "react";
import axios from "axios";
import { useLazyGetVehicleBrandIdsQuery } from "../app/services/vehicleBrand.api";

// Custom hook to fetch vehicle data
export const useGetBrandsHook = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
    const [getVehicleBrandIds] = useLazyGetVehicleBrandIdsQuery();
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        // Generate an array of URLs for the JSON files
        const idsResponse = await getVehicleBrandIds().unwrap();

        const urls = idsResponse.map(
          (id) =>
            `https://d3jbkxdk2o6mwn.cloudfront.net/vehicle-master-data-dev/${id}.json`
        );

        
        // const urls = Array.from(
        //   { length: 96 },
        //   (_, i) =>
        //     `https://ctn-uploads.s3.ap-northeast-1.amazonaws.com/${
        //       i + 1
        //     }.json`
        // );

        // Create an array of axios get requests
        const fetchRequests = urls.map((url) =>
          axios
            .get(url, {
              headers: {
                "Accept-Encoding": "gzip,br",
              },
            })
            .catch((error) => {
              console.error(`Error fetching ${url}: `, error);
              return { data: [] }; // Return an empty array on error
            })
        );

        // Wait for all axios requests to resolve
        const responses = await Promise.all(fetchRequests);

        // Extract data from all responses
        const dataArray = responses.map((response) => response.data);

        // Merge all data into a single array
        const mergedData = dataArray.flat();

        // Set the merged data to your state
        setData(mergedData);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data: ", error);
        setError(error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return { data, isLoading, error };
};
